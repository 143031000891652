import {authStore, UIStore} from "./stores";

export function handleApiJson(data) {

}

export function fetchSelf() {
    fetch(process.env.REACT_APP_API_URL + 'api/v1/self/', {
        headers: {
            "Authorization": 'Token ' + localStorage.token
        },
        method: "get"
    }).then((res) => {

        if (res.status !== 200) {
            delete localStorage.token
            authStore.update(s => {
                s.token = null
            })
        } else {
            res.json().then((data) => {
                handleApiJson(data)
                authStore.update(s => {
                    s.self = data['data']
                    s.is_admin = data['data']['is_superuser']
                })
            })
        }
    })
}

export function fetchTypes() {
    fetch(process.env.REACT_APP_API_URL + `api/v1/question_types/`, {
        headers: {
            "Authorization": 'Token ' + localStorage.token
        },
        method: "get"
    }).then((res) => {
        if (res.status !== 200) {

        } else {
            res.json().then((data) => {
                UIStore.update(s => {
                    s.types = data['data']
                })
            })
        }
    })
}

fetchTypes()
setInterval(fetchTypes, 10000);

export function fetchCategories() {
    fetch(process.env.REACT_APP_API_URL + `api/v1/question_categories/`, {
        headers: {
            "Authorization": 'Token ' + localStorage.token
        },
        method: "get"
    }).then((res) => {
        if (res.status !== 200) {

        } else {
            res.json().then((data) => {
                UIStore.update(s => {
                    s.categories = data['data']
                })
            })
        }
    })
}

fetchCategories()
setInterval(fetchCategories, 10000);

function handleApiStatus(status) {

}

export function convertIsoToReadable(isoDate) {
    if (!isoDate) {
        return ''
    }
    let readable = new Date(isoDate);
    let m = readable.getMonth();
    let d = readable.getDate();  // returns 15
    let y = readable.getFullYear();  // returns 15
    let h = readable.getHours();  // returns 2012
    let mi = readable.getMinutes();  // returns 2012

// we define an array of the months in a year
    let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

// we get the text name of the month by using the value of m to find the corresponding month name
    let mlong = months[m];


    return mlong + " " + d + ", " + y + ` ${h}:${mi}`
}
export function prettifyCamelCase(str) {
    let output = "";
    const len = str.length;
    let char;

    for (let i=0 ; i<len ; i++) {
        char = str.charAt(i);

        if (i===0) {
            output += char.toUpperCase();
        }
        else if (char !== char.toLowerCase() && char === char.toUpperCase()) {
            output += " " + char;
        }
        else if (char === "-" || char === "_") {
            output += " ";
        }
        else {
            output += char;
        }
    }

    return output;
}