import * as React from 'react';
import {useEffect} from 'react';
import {DataGrid} from '@material-ui/data-grid';
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import history from "history/browser";
import {UIStore} from "./stores";
import {convertIsoToReadable} from "./utils";
import Fab from "@material-ui/core/Fab";
import {Search} from "@material-ui/icons";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import {isMobile} from "react-device-detect";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {mainActionButtonPress} from "./subjects";

const UsersStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
    },
    formControl: {
        marginRight: theme.spacing(3),
        minWidth: 120,
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    fab: {
        bottom: "45px", right: "40px", position: "fixed"
    }
}));
const columns = [
    {field: 'id', headerName: 'ID', width: 70},
    {field: 'first_name', headerName: 'First name', width: 130},
    {field: 'last_name', headerName: 'Last name', width: 130},
    {field: 'username', headerName: 'Username', width: 130},
    {field: 'email', headerName: 'Email', width: 130},
    {
        field: 'date_joined',
        headerName: 'Date Joined',
        width: 200,
        valueGetter: (params) => {
            return convertIsoToReadable(params.row.date_joined)
        }
    },
    {
        field: 'expiration',
        headerName: 'Expiration',
        width: 200,
        valueGetter: (params) => {
            return convertIsoToReadable(params.row.expiration)
        }
    }, {
        field: 'updated_at',
        headerName: 'Updated',
        width: 200,
        valueGetter: (params) => {
            return convertIsoToReadable(params.row.updated_at)
        }
    },
    {
        field: 'is_active',
        headerName: 'Is Active',
        type: 'bool',
        width: 150,
    }, {
        field: 'is_superuser',
        headerName: 'Is SuperUser',
        type: 'bool',
        width: 150,
    },

    // {
    //     field: 'fullName',
    //     headerName: 'Full name',
    //     description: 'This column has a value getter and is not sortable.',
    //     sortable: false,
    //     width: 160,
    //     valueGetter: (params) =>
    //         `${params.getValue('firstName') || ''} ${params.getValue('lastName') || ''}`,
    // },
];

export function UsersList(props) {
    const classes = UsersStyles()

    const [usersList, setUsersList] = React.useState([])
    const [pages, setPages] = React.useState(1)
    const pageSize = isMobile ? 5 : 10
    const [currentPage, setCurrentPage] = React.useState(1)
    const [search, setSearch] = React.useState('');
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseApply = () => {
        setOpen(false);
        fetchUsers()
    };

    const handleCloseReset = () => {
        setSearch(null)
        setOpen(false);
        fetchUsers()
    };

    let order = null

    useEffect(() => {
        fetchUsers()
    }, [])

    useEffect(() => {
        UIStore.update(s => {
            s.pageTitle = "Users"
        })
        UIStore.update(s => {
            s.mainActionButton = 'users_refresh'
        })
        const uns = mainActionButtonPress.subscribe({
            next: (d) => {
                if (d === 'users_refresh') {
                    fetchUsers()
                }
            }
        })
        return () => {
            UIStore.update(s => {
                s.mainActionButton = null
            })
            uns.unsubscribe()
        };
    }, []);

    function fetchUsers() {

        UIStore.update((s) => {
            s.loading = true
        })

        let ordering = ''

        if (order) {
            if (order.sort !== 'asc') {
                ordering += '-'
            }
            ordering += order.field
        }

        const url = process.env.REACT_APP_API_URL + `api/v1/users/?` + new URLSearchParams({
            page: currentPage,
            page_size: pageSize,
            ordering: ordering,
            search: search
        })

        fetch(url, {
            headers: {
                "Authorization": 'Token ' + localStorage.token
            },
            method: "get"
        }).then((res) => {
            UIStore.update((s) => {
                s.loading = false
            })
            if (res.status !== 200) {
                history.back()
            } else {
                res.json().then((data) => {
                    setUsersList(data['data'])
                    setPages(data['pagination']['items_count'])
                })
            }
        })
    }

    return (
        <Container style={{maxWidth: 'none'}} className={classes.paper} component="div" maxWidth="xs">
            <div style={{height: 400, width: '100%'}}>
                <DataGrid rows={usersList} page={currentPage}
                          paginationMode="server"
                          sortingMode="server"
                          autoHeight
                          onPageChange={param => {
                              setCurrentPage(param.page)
                              fetchUsers()
                          }}
                          onRowClick={param => {
                              window.open(`/users/${param.row.id}/edit`, "_blank")
                          }}
                          onSortModelChange={params => {
                              if (params.sortModel.length !== 0) {
                                  order = params.sortModel[0]
                              } else {
                                  order = null
                              }
                              fetchUsers()
                          }}
                          columns={columns} rowCount={pages} pageSize={pageSize}/>
            </div>
            <br/><br/><br/><br/><br/><br/><br/><br/>
            <Fab
                variant="extended"
                size="large"
                color="primary"
                aria-label="add"
                onClick={handleClickOpen}
                className={classes.fab}
            >
                <Search className={classes.extendedIcon}/>
                Search
            </Fab>
            <Dialog fullWidth={true} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Search</DialogTitle>
                <DialogContent>
                    <TextField
                        margin="dense"
                        label="Search"
                        type="text"
                        fullWidth
                        value={search}
                        onChange={e => {
                            setSearch(e.target.value)
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleCloseApply} color="primary">
                        Apply
                    </Button>
                    <Button onClick={handleCloseReset} color="primary">
                        Reset
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}