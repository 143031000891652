import {authStore, globalSnackError, UIStore} from "./stores";
import React, {useEffect} from "react";
import {handleApiJson} from "./utils";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import CategoryTypeCard from "./CategoryTypeCard";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {Add} from "@material-ui/icons";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";

const TypesAdminUseStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    fab: {
        bottom: "45px", right: "40px", position: "fixed"
    }
}));

export function TypesAdmin(props) {
    useEffect(() => {
        fetchList()
    }, []);

    useEffect(() => {
        setTypesList(null)
        fetchList()
        UIStore.update(s => {
            s.pageTitle = props.is_type ? 'Types' : 'Categories'
        })
    }, [props.is_type])

    const isAdmin = authStore.useState(s => s.is_admin);
    const [typesList, setTypesList] = React.useState(null);


    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const classes = TypesAdminUseStyles();

    function fetchList() {
        fetch(process.env.REACT_APP_API_URL + `api/v1/${props.is_type ? 'question_types' : 'question_categories'}/`, {
            headers: {
                "Authorization": 'Token ' + localStorage.token
            },
            method: "get"
        }).then((res) => {
            if (res.status !== 200) {
            } else {
                res.json().then((data) => {
                    handleApiJson(data)
                    setTypesList(data['data'])
                })
            }
        })
    }

    const [newItemTitle, setNewItemTitle] = React.useState('');
    const [newItemFrTitle, setNewItemFrTitle] = React.useState('');
    const [newItemSlug, setNewItemSlug] = React.useState('');
    const [newCategoryFinalQuestions, setNewCategoryFinalQuestions] = React.useState(1);

    function addItem() {
        fetch(process.env.REACT_APP_API_URL + `api/v1/${props.is_type ? 'question_types' : 'question_categories'}/`, {
            headers: {
                "Authorization": 'Token ' + localStorage.token,
                "Content-Type": "application/json"
            },
            method: "post",
            body: JSON.stringify({
                'title': newItemTitle,
                'slug': newItemSlug,
                'translations': {
                    'fr_title': newItemFrTitle
                },
                'final_questions': parseInt(newCategoryFinalQuestions)
            })
        }).then((res) => {
            fetchList()
            handleClose()
            if (res.status === 201) {
                setNewItemTitle('')
                setNewItemFrTitle('')
                setNewItemSlug('')
            } else {
                res.json().then((data) => {
                    handleApiJson(data)
                    globalSnackError.update(s => {
                        s.display = true;
                        s.text = data["errors"][0].message || data["errors"][0].error_type;
                    })
                })
            }
        })
    }


    return isAdmin ? <div>
        {typesList && <Container style={{maxWidth: 'none'}} className={classes.paper} component="div" maxWidth="xs">
            <CssBaseline/>
            <Grid container spacing={3}>
                {typesList.map(x => <Grid key={x.slug} item md={6} xs={12} lg={4}><CategoryTypeCard key={x.slug}
                                                                                                    data={x}
                                                                                                    is_type={props.is_type}/></Grid>)}
            </Grid>
        </Container>}
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">New {props.is_type ? 'Type' : 'Category'}</DialogTitle>
            <DialogContent>
                <TextField
                    margin="dense"
                    label="Title"
                    type="text"
                    fullWidth
                    value={newItemTitle}
                    onChange={e => {
                        setNewItemTitle(e.target.value)
                    }}
                />
                <TextField
                    margin="dense"
                    label="French Title"
                    type="text"
                    fullWidth
                    value={newItemFrTitle}
                    onChange={e => {
                        setNewItemFrTitle(e.target.value)
                    }}
                />
                <TextField
                    margin="dense"
                    label="Slug"
                    type="text"
                    fullWidth
                    value={newItemSlug}
                    onChange={e => {
                        setNewItemSlug(e.target.value)
                    }}
                />
                {!props.is_type && <TextField
                    margin="dense"
                    label="Final Questions"
                    type="text"
                    fullWidth
                    value={newCategoryFinalQuestions}
                    onChange={e => {
                        setNewCategoryFinalQuestions(e.target.value)
                    }}
                />}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={addItem} color="primary">
                    Add
                </Button>
            </DialogActions>
        </Dialog>
        <Fab color="secondary" onClick={handleClickOpen} className={classes.fab} variant="extended">
            <Add className={classes.extendedIcon}/>
            Add {props.is_type ? 'Type' : 'Category'}
        </Fab>
    </div> : <div></div>
}