import {createMuiTheme} from "@material-ui/core";
import green from "@material-ui/core/colors/green";
import orange from "@material-ui/core/colors/orange";
import {deviceType, isMobile} from "react-device-detect";

const theme = createMuiTheme({
    palette: {
        type: window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light",
        // type:  "dark",
        primary: green,
        secondary: orange,
    },
    typography: {
        fontSize: 15,
    body1: {
      fontSize: ["tablet", "browser"].includes(deviceType)? "1.3rem" : "1rem",
    }}
});

export function getImageStyle(pictureW, pictureH) {
    console.log(deviceType, ["mobile", "tablet", "browser"].includes(deviceType))
    if (!["mobile", "tablet", "browser"].includes(deviceType)){
        return {width: '100vw', height: "20vw"}
    }
    if ((Math.max.apply(0, [pictureW, pictureH]) - Math.min.apply(0, [pictureW, pictureH])) < 30) {
        return {
            "tablet": {width: '35vw', height: "35vw"},
            "mobile": {width: '60vw', height: "60vw"},
            "browser": {width: '15vw', height: "15vw"},
        }[deviceType]
    } else if (pictureW < pictureH) {
        return {
            "tablet": {width: '35vw', height: "50vw"},
            "mobile": {width: '60vw', height: "60vw"},
            "browser": {width: '16vw', height: "20vw"},
        }[deviceType]
    } else if (pictureW > pictureH) {
        return {
            "tablet": {width: '45vw', height: "35vw"},
            "mobile": {width: '85vw', height: "50vw"},
            "browser": {width: '20vw', height: "15vw"},
        }[deviceType]
    }
}

export default theme