import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import {authStore, globalSnackError, UIStore} from "./stores";
import history from 'history/browser';
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import {Image, Info, Looks3, Looks4, LooksOne, LooksTwo, Save} from "@material-ui/icons";
import StepContent from "@material-ui/core/StepContent";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import {isMobile} from "react-device-detect";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {Divider, Switch} from "@material-ui/core";
import {editQuestionSubject} from "./subjects";
import Icon from "@material-ui/core/Icon";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import {getImageStyle} from "./theme";


const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const NewQuestionUseStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    imagesButton: {
        '& > *': {
            margin: theme.spacing(1),
        },
        whiteSpace: isMobile ? "break-spaces" : "nowrap"
    },
    editorStyle: {
        textAlign: 'left',
        marginBottom: 10
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    choiceHeadStyle: {
        textAlign: 'left',
        marginBottom: 25
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    divider: {
        marginTop: 30,
        marginBottom: 30,
    },
    picture: {
        display: "flex",
        marginBottom: 15
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    appBar: {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper,
        boxShadow: 'none',
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditQuestion(props) {
    const [open, setOpen] = React.useState(false);
    const [data, setData] = React.useState(null);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const openGlobalModal = UIStore.useState(s => s.openGlobalModal)
    const readOnly = UIStore.useState(s => s.editQuestionReadOnly)

    // useEffect(() => {
    //     fetchData(props.question_slug)
    //
    // }, [props.question_slug])
    //
    // useEffect(() => {
    //
    //     fetchData(props.question_slug)
    // }, []);


    const classes = NewQuestionUseStyles();
    const [enText, setEnText] = React.useState('')
    const [frText, setFrText] = React.useState('')

    function handleSetCorrect(data) {
        for (let key in data) {
            if (key === 'choice_1_correct_answer') {
                if (data[key]) {
                    SetCorrectAnswerId(key)
                    setCorrectSet(true)
                }
            }
            if (key === 'choice_2_correct_answer') {
                if (data[key]) {
                    SetCorrectAnswerId(key)
                    setCorrectSet(true)
                }
            }
            if (key === 'choice_3_correct_answer') {
                if (data[key]) {
                    SetCorrectAnswerId(key)
                    setCorrectSet(true)
                }
            }
            if (key === 'choice_4_correct_answer') {
                if (data[key]) {
                    SetCorrectAnswerId(key)
                    setCorrectSet(true)
                }
            }
        }
    }

    useEffect(() => {
        let unlisten = history.listen(({action, location}) => {
            if (openGlobalModal === 'edit_question') {
                UIStore.update(s => {
                    s.openGlobalModal = null
                    s.editQuestionSlug = null
                    s.editQuestionReadOnly = false
                    s.questionData = null
                })
                setData(null)
            }

        });
        const unsubscribe = UIStore.subscribe(
            s => s.openGlobalModal,
            (watched, allState, prevWatched) => {
                if (watched === 'edit_question') {
                    if (allState.questionData && allState.editQuestionSlug) {
                        setData(allState.questionData)
                        setFrText(allState.questionData['fr_text'])
                        setEnText(allState.questionData['text'])
                        handleSetCorrect(allState.questionData)
                    } else {
                        fetchData(allState.editQuestionSlug)
                    }


                }
            }
        );
        return () => {
            unlisten()
            unsubscribe()
        }
    },);

    function fetchData(slug) {
        if (!slug) {
            return
        }
        UIStore.update((s) => {
            s.loading = true
        })
        fetch(process.env.REACT_APP_API_URL + `api/v1/questions/${slug}/`,
            {
                headers: {
                    "Authorization": 'Token ' + localStorage.token
                },
                method: "get"
            }).then((res) => {
            UIStore.update(s => {
                s.loading = false
            })
            if (res.status === 200) {
                res.json().then(data => {
                    setFrText(data['data']['fr_text'])
                    setEnText(data['data']['text'])
                    setData(data['data'])
                    handleSetCorrect(data['data'])
                })
            } else {
                globalSnackError.update(s => {
                    s.display = true;
                    s.text = "Failed fetching this question.";
                })
                history.back()
            }
        })
    }


    const handleClose = () => {
        history.back();
    };


    const types = UIStore.useState(s => s.types)
    const categories = UIStore.useState(s => s.categories)

    const [activeStep, setActiveStep] = React.useState(5);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleNextQuestion = (order) => {

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const [loading, setLoading] = React.useState(false)

    function handleForm() {
        UIStore.update(s => {
            s.loading = true
        })
        const fData = new FormData(formRef.current)
        fetch(process.env.REACT_APP_API_URL + `api/v1/questions/${data.slug}/`, {
            body: fData,
            method: "put",
            headers: {
                "Authorization": 'Token ' + localStorage.token
            },
        }).then((res) => {
            UIStore.update(s => {
                s.loading = false
            })
            res.json().then((data) => {
                setLoading(false)
                if (res.status === 400) {
                    globalSnackError.update(s => {
                        s.display = true;
                        s.text = data["errors"][0].message || data["errors"][0].error_type;
                    })
                } else if (res.status === 200) {
                    editQuestionSubject.next(data['data'])
                    history.back()
                }
            })
        })
    }

    function getIcon(i) {
        return {
            "1": LooksOne,
            "2": LooksTwo,
            "3": Looks3,
            "4": Looks4,
        }[i]
    }

    const formRef = React.useRef();

    const [displayImage, setDisplayImage] = React.useState(null)

    function handleImageChange(event) {
        setDisplayImage(null)
        setDisplayImage(URL.createObjectURL(event.target.files[0]))
    }

    const [pictureW, setPictureW] = React.useState(null)
    const [pictureH, setPictureH] = React.useState(null)

    const [deleteImage, setDeleteImage] = React.useState(false)

    function onImgLoad(img) {
        setPictureW(img.target.offsetWidth)
        setPictureH(img.target.offsetHeight)
    }


    const [correctSet, setCorrectSet] = React.useState(true)
    const [correctAnswerId, SetCorrectAnswerId] = React.useState('')

    const isAdmin = authStore.useState(s => s.is_admin);
    const [answerValue, setAnswerValue] = React.useState(null);


    const handleAnswerChange = (event) => {
        setAnswerValue(event.target.value);
        console.log(event.target.value)
    };
    return <div>
        <Dialog fullScreen open={openGlobalModal === 'edit_question'} onClose={handleClose}
                TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon/>
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        {readOnly ? "" : "Edit"} Question
                    </Typography>
                    {!readOnly && <Button
                        variant="outlined"
                        size="large"
                        startIcon={<Save/>}
                        onClick={() => {
                            handleForm()
                        }}
                    >Save
                    </Button>}
                </Toolbar>
            </AppBar>
            {isAdmin && data ? <div className={classes.paper}>
                <Container component="main" maxWidth="xs">
                    {readOnly ? <div><FormControl component="fieldset"
                                                  className={classes.formControl}>
                        <Typography variant="subtitle1" gutterBottom className={classes.formLabel} component="legend">
                            <div dangerouslySetInnerHTML={{__html: data.text}}>
                            </div>
                        </Typography>
                        {data && data.image && <div className={classes.picture} style={getImageStyle(pictureW, pictureH)}>
                            <img onLoad={onImgLoad} alt='Test Image' src={data.image}/>
                        </div>}
                        <Divider/>
                        <RadioGroup onChange={handleAnswerChange} value={answerValue} aria-label="quiz" name="quiz">
                            <FormControlLabel value={data.choice_1_id} control={<Radio/>}
                                              label={data.choice_1_text}/>
                            <FormControlLabel value={data.choice_2_id} control={<Radio/>}
                                              label={data.choice_2_text}/>
                            <FormControlLabel value={data.choice_3_id} control={<Radio/>}
                                              label={data.choice_3_text}/>
                            <FormControlLabel value={data.choice_4_id} control={<Radio/>}
                                              label={data.choice_4_text}/>
                        </RadioGroup>

                    </FormControl></div> : <form autocomplete="off" onSubmit={e => {
                        e.preventDefault()
                    }} ref={formRef}>
                        <CssBaseline/>
                        <Stepper TransitionComponent="None" orientation="vertical" activeStep={activeStep}>
                            <Step key='question_info'>
                                <StepLabel StepIconComponent={Info}>Question Info</StepLabel>
                                <StepContent TransitionComponent="None">
                                    <Typography className={classes.editorStyle} variant="subtitle1" gutterBottom>
                                        English Title
                                    </Typography>
                                    <div style={{color: 'black'}}><CKEditor
                                        editor={ClassicEditor}
                                        data={enText}
                                        config={{
                                            toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote']
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            setEnText(data)
                                        }}
                                    /></div>
                                    <input type="hidden" name='text' value={enText}/><br/><br/>
                                    <Typography className={classes.editorStyle} variant="subtitle1" gutterBottom>
                                        French Title
                                    </Typography>
                                    <div style={{color: 'black'}}><CKEditor
                                        style={{color: 'black'}}
                                        editor={ClassicEditor}
                                        data={frText}
                                        config={{

                                            toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote']
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            setFrText(data)
                                        }}
                                    /></div>
                                    <input type="hidden" name='fr_text' value={frText}/><br/>

                                    {deleteImage && <input type="hidden" name='remove_image' value="true"/>}
                                    <Typography className={classes.editorStyle} variant="subtitle1" gutterBottom>
                                        Type and Category
                                    </Typography>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel htmlFor="age-native-simple">Type</InputLabel>
                                        <Select
                                            native={isMobile}
                                            defaultValue={data['type_id']}
                                            inputProps={{
                                                name: 'type_id',
                                            }}>
                                            {isMobile ? types.map(t => <option value={t.id}>{t.title}</option>) :
                                                types.map(t => <MenuItem value={t.id}>{t.title}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel htmlFor="age-native-simple">Category</InputLabel>
                                        <Select
                                            native={isMobile}
                                            inputProps={{
                                                name: 'category_id',
                                            }}
                                            defaultValue={data['category_id']}
                                        >
                                            {isMobile ? categories.map(t => <option
                                                    value={t.id}>{t.title}</option>) :
                                                categories.map(t => <MenuItem
                                                    value={t.id}>{t.title}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>

                                    <Typography className={classes.editorStyle} variant="subtitle1" gutterBottom>
                                        Image
                                    </Typography>
                                    {!displayImage && data.image && !deleteImage &&
                                    <div className={classes.picture} style={getImageStyle(pictureW, pictureH)}>
                                        <img onLoad={onImgLoad} alt='Test Image' src={data.image}/>
                                    </div>}
                                    {displayImage && <div className={classes.picture} style={getImageStyle(pictureW, pictureH)}>
                                        <img onLoad={onImgLoad} alt='Test Image' src={displayImage}/>
                                    </div>}
                                    <input
                                        accept="image/*"
                                        style={{display: "none"}}
                                        id="edit-question-image"
                                        type="file"
                                        name="image"
                                        onChange={handleImageChange}
                                    />
                                    <div className={classes.imagesButton}>
                                        {!displayImage && !deleteImage &&
                                        <Button onClick={() => {
                                            document.getElementById("edit-question-image").click()
                                            setDeleteImage(false)
                                        }} startIcon={<Image/>} variant="contained" color="primary"
                                                component="span">
                                            {data.image && !deleteImage ? "Change" : "Add"} Image
                                        </Button>}
                                        {!displayImage && deleteImage &&
                                        <Button onClick={() => {
                                            setDeleteImage(false)
                                        }} startIcon={<Icon>restore_from_trash</Icon>} variant="contained"
                                                color="primary"
                                                component="span">
                                            Restore Image
                                        </Button>}
                                        {displayImage && <Button onClick={() => {
                                            setDisplayImage(null)
                                            setDeleteImage(false)
                                            document.getElementById("edit-question-image").value = "";
                                        }} startIcon={<Icon>restart_alt</Icon>} variant="outlined" color="primary"
                                                                 component="span">
                                            Reset Image
                                        </Button>}
                                        {!displayImage && data.image && !deleteImage && <Button onClick={() => {
                                            setDeleteImage(true)
                                            document.getElementById("edit-question-image").value = "";
                                        }} startIcon={<Icon>restart_alt</Icon>} variant="outlined" color="primary"
                                                                                                component="span">
                                            Delete Image
                                        </Button>}
                                    </div>


                                </StepContent>
                            </Step>
                            {
                                ["1", "2", "3", "4"].map(i => {
                                    return <Step unmountOnExit={false} key={`choice_${i}`}>
                                        <StepLabel StepIconComponent={getIcon(i)}>Choice #{i}</StepLabel>
                                        <StepContent TransitionComponent="None">
                                            <TextField defaultValue={data[`choice_${i}_text`]} fullWidth required
                                                       label="English Title" name={`choice_${i}_text`}
                                                       variant="outlined"/>
                                            <br/><br/>
                                            <TextField defaultValue={data[`choice_${i}_fr_text`]} fullWidth required
                                                       label="French Title" name={`choice_${i}_fr_text`}
                                                       variant="outlined"/>
                                            <FormControlLabel
                                                control={<Switch
                                                    name={`choice_${i}_correct_answer`}
                                                    inputProps={{'aria-label': 'secondary checkbox'}}
                                                    value='true'
                                                    disabled={correctAnswerId !== `choice_${i}_correct_answer` && correctSet}
                                                    onChange={(event, checked) => {
                                                        console.log()
                                                        SetCorrectAnswerId(checked ? event.target.name : null)
                                                        setCorrectSet(checked)
                                                    }}
                                                    defaultChecked={data[`choice_${i}_correct_answer`]}
                                                />}
                                                label="Correct Answer"
                                            />
                                            <br/>

                                        </StepContent>
                                    </Step>
                                })
                            }
                        </Stepper>
                    </form>}
                </Container>
            </div> : <div></div>}
        </Dialog>

    </div>
}