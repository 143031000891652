import {authStore, globalSnackError, UIStore} from "./stores";
import React, {useEffect} from "react";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import {isMobile} from "react-device-detect";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import {handleApiJson} from "./utils";
import TextField from "@material-ui/core/TextField";
import Stepper from "@material-ui/core/Stepper";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Step from "@material-ui/core/Step";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {Image, Info, Looks3, Looks4, LooksOne, LooksTwo} from "@material-ui/icons";
import {Switch} from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import {getImageStyle} from "./theme";

const NewQuestionUseStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    stepperBase: {
        backgroundColor: theme.palette.background.default
    },
    imagesButton: {
        '& > *': {
            margin: theme.spacing(1),
        },
        whiteSpace: isMobile ? "break-spaces" : "nowrap"
    },
    picture: {
        marginBottom: 15,
        display: "flex"
    },
    editorStyle: {
        textAlign: 'left',
        marginBottom: 10
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    choiceHeadStyle: {
        textAlign: 'left',
        marginBottom: 25
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    divider: {
        marginTop: 30,
        marginBottom: 30,
    }
}));

export function NewQuestion(props) {
    const classes = NewQuestionUseStyles();
    const [enText, setEnText] = React.useState('')
    const [frText, setFrText] = React.useState('')

    const types = UIStore.useState(s => s.types)
    const categories = UIStore.useState(s => s.categories)

    const [activeStep, setActiveStep] = React.useState(5);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleNextQuestion = (order) => {

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const [loading, setLoading] = React.useState(false)

    const [correctSet, setCorrectSet] = React.useState(false)
    const [correctAnswerId, SetCorrectAnswerId] = React.useState('')


    function handleForm(event) {
        event.preventDefault();
        const data = new FormData(event.target)
        UIStore.update((s) => {
            s.loading = true
        })
        setLoading(true)
        fetch(process.env.REACT_APP_API_URL + 'api/v1/questions/', {
            body: data,
            method: "post",
            headers: {
                "Authorization": 'Token ' + localStorage.token
            },
        }).then((res) => {
            UIStore.update((s) => {
                s.loading = false
            })
            res.json().then((data) => {
                handleApiJson(data)
                setLoading(false)
                if (res.status === 400) {
                    globalSnackError.update(s => {
                        s.display = true;
                        s.text = data["errors"][0].message || data["errors"][0].error_type;
                    })
                } else if (res.status === 201) {
                    props.history.push('/questions')
                }
            })
        })
    }

    function getIcon(i) {
        return {
            "1": LooksOne,
            "2": LooksTwo,
            "3": Looks3,
            "4": Looks4,
        }[i]
    }

    useEffect(() => {
        UIStore.update(s => {
            s.pageTitle = "New Question"
        })

    }, [])


    const [displayImage, setDisplayImage] = React.useState(null)

    function handleImageChange(event) {
        setDisplayImage(null)
        setDisplayImage(URL.createObjectURL(event.target.files[0]))
    }

    const [pictureW, setPictureW] = React.useState(null)
    const [pictureH, setPictureH] = React.useState(null)

    const [deleteImage, setDeleteImage] = React.useState(false)

    function onImgLoad(img) {
        setPictureW(img.target.offsetWidth)
        setPictureH(img.target.offsetHeight)
    }


    const isAdmin = authStore.useState(s => s.is_admin);
    return isAdmin ? <div className={classes.paper}>
        <Container component="main" maxWidth="xs">
            <form autocomplete="off" onSubmit={handleForm}>
                <CssBaseline/>
                <Stepper className={classes.stepperBase} TransitionComponent="None" orientation="vertical"
                         activeStep={activeStep}>
                    <Step key='question_info'>
                        <StepLabel StepIconComponent={Info}>Question Info</StepLabel>
                        <StepContent TransitionComponent="None">
                            <Typography className={classes.editorStyle} variant="subtitle1" gutterBottom>
                                English Title
                            </Typography>
                            <div style={{color: 'black'}}><CKEditor
                                editor={ClassicEditor}
                                data={enText}
                                config={{
                                    toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote']
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setEnText(data)
                                }}
                            /></div>
                            <input type="hidden" name='text' value={enText}/><br/><br/>
                            <Typography className={classes.editorStyle} variant="subtitle1" gutterBottom>
                                French Title
                            </Typography>
                            <div style={{color: 'black'}}><CKEditor
                                editor={ClassicEditor}
                                data={frText}
                                config={{

                                    toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote']
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setFrText(data)
                                }}
                            /></div>
                            <input type="hidden" name='fr_text' value={frText}/><br/>
                            <Typography className={classes.editorStyle} variant="subtitle1" gutterBottom>
                                Type and Category
                            </Typography>
                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="age-native-simple">Type</InputLabel>
                                <Select
                                    native={isMobile}
                                    inputProps={{
                                        name: 'type_id',
                                    }}>
                                    {isMobile ? types.map(t => <option value={t.id}>{t.title}</option>) :
                                        types.map(t => <MenuItem value={t.id}>{t.title}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="age-native-simple">Category</InputLabel>
                                <Select
                                    native={isMobile}
                                    inputProps={{
                                        name: 'category_id',
                                    }}
                                >
                                    {isMobile ? categories.map(t => <option value={t.id}>{t.title}</option>) :
                                        categories.map(t => <MenuItem value={t.id}>{t.title}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                            <Typography className={classes.editorStyle} variant="subtitle1" gutterBottom>
                                Image
                            </Typography>
                            {displayImage && <div className={classes.picture} style={getImageStyle(pictureW, pictureH)}>
                                <img onLoad={onImgLoad} alt='Test Image' src={displayImage}/>
                            </div>}
                            <input
                                accept="image/*"
                                style={{display: "none"}}
                                id="edit-question-image"
                                type="file"
                                name="image"
                                onChange={handleImageChange}
                            />
                            <div className={classes.imagesButton}>
                                {!displayImage &&
                                <Button onClick={() => {
                                    document.getElementById("edit-question-image").click()
                                }} startIcon={<Image/>} variant="contained" color="primary"
                                        component="span">
                                    Add Image
                                </Button>}
                                {displayImage && <Button onClick={() => {
                                    setDisplayImage(null)
                                    document.getElementById("edit-question-image").value = "";
                                }} startIcon={<Icon>remove</Icon>} variant="outlined" color="primary"
                                                         component="span">
                                    Remove Image
                                </Button>}
                            </div>
                        </StepContent>
                    </Step>
                    {
                        ["1", "2", "3", "4"].map(i => {
                            return <Step unmountOnExit={false} key={`choice_${i}`}>
                                <StepLabel StepIconComponent={getIcon(i)} >Choice #{i}</StepLabel>
                                <StepContent  TransitionComponent="None">
                                    <TextField fullWidth required label="English Title" name={`choice_${i}_text`}
                                               variant="outlined"/>
                                    <br/><br/>
                                    <TextField fullWidth required label="French Title" name={`choice_${i}_fr_text`}
                                               variant="outlined"/>
                                    <FormControlLabel
                                        control={<Switch
                                            name={`choice_${i}_correct_answer`}
                                            inputProps={{'aria-label': 'secondary checkbox'}}
                                            value='true'
                                            disabled={correctAnswerId !== `choice_${i}_correct_answer` && correctSet}
                                            onChange={(event, checked) => {
                                                console.log()
                                                SetCorrectAnswerId(checked ? event.target.name : null)
                                                setCorrectSet(checked)
                                            }}
                                        />}
                                        label="Correct Answer"
                                    />
                                    <br/>
                                    {i === "4" && <div className={classes.actionsContainer}>
                                        <div>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                type={i === "4" ? 'submit' : 'button'}
                                                onClick={i === "4" ? null : handleNext}
                                                className={classes.button}>
                                                {i === "4" ? 'Finish' : "Next"}
                                            </Button>
                                        </div>
                                    </div>}

                                </StepContent>
                            </Step>
                        })
                    }
                </Stepper>
            </form>
        </Container>

    </div> : <div>fsdsfd</div>
}