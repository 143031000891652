import LocalizedStrings from 'react-localization';

const S = new LocalizedStrings({
    en: {
        registerTitle: "Register",
        emailAddress: "Email Address",
        firstName: "First Name",
        lastName: "Last Name",
        password: "Password",
        username: "Username",
        tests: "Tests",
        SuccessfulLogin: "Login was successful.",
        home: "Home",
        signIn: "Sign In",
        register: "Register",
        siteTitle: "NATIONAL",
        wrongCredInfo: "Wrong username or password",
        logout: "Logout",
        admin: "Admin",
        categories: "Categories",
        types: "Types",
        profile: "Profile",
        newTest: "New Test",
    },
    fr: {

    }
});

export default S;