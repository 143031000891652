import {makeStyles} from "@material-ui/core/styles";
import {fetchSelf, handleApiJson} from "./utils";
import {authStore, globalSnackError, globalSnackSuccess, UIStore} from "./stores";
import S from "./Translations";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import React, {useEffect} from "react";
import {Link, Redirect} from "react-router-dom";
import theme from "./theme";

const RegisterUseStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export function Register() {
    const classes = RegisterUseStyles();

    const [redirect, setRedirect] = React.useState(false)
    useEffect(() => {
        UIStore.update(s => {
            s.pageTitle = S.registerTitle
        })

    }, [])

    if (redirect) {
        return <Redirect to='/new_test'/>
    }

    function handleForm(event) {
        event.preventDefault();
        const data = new FormData(event.target)
        fetch(process.env.REACT_APP_API_URL + 'api/v1/register/', {
            body: data,
            method: "post"
        }).then((res) => {
            res.json().then((data) => {
                handleApiJson(data)
                if (res.status === 400) {
                    globalSnackError.update(s => {
                        s.display = true;
                        s.text = data["errors"][0].message || data["errors"][0].error_type;
                    })
                } else if (res.status === 200) {
                    globalSnackSuccess.update(s => {
                        s.display = true;
                        s.text = S.SuccessfulLogin;
                    })
                    localStorage.token = data['data']['token']
                    authStore.update(s => {
                        s.token = data['data']['token']
                    })
                    fetchSelf()
                    setRedirect(true)
                }
            })
        })
    }


    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    {S.registerTitle}
                </Typography>
                <form autocomplete="off" onSubmit={handleForm} className={classes.form}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label={S.emailAddress}
                        name="email"
                        autoComplete="email"
                        autoFocus
                        type="email"
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label={S.username}
                        name="username"
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="first_name"
                        label={S.firstName}
                        name="first_name"
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="last_name"
                        label={S.lastName}
                        name="last_name"
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label={S.password}
                        type="password"
                        id="password"
                    />
                    <Button
                        type="submit"
                        fullWidth

                        variant="outlined"
                        color="primary"
                        className={classes.submit}
                    >
                        {S.registerTitle}
                    </Button>
                </form>
            </div>
        </Container>
    )
}

export function LoginPage() {
    const classes = RegisterUseStyles();

    function handleForm(event) {
        event.preventDefault();
        const data = new FormData(event.target)
        fetch(process.env.REACT_APP_API_URL + 'api/v1/login/', {
            body: data,
            method: "post"
        }).then((res) => {
            if (res.status === 400) {
                globalSnackError.update(s => {
                    s.display = true;
                    s.text = S.wrongCredInfo
                })
            } else if (res.status === 200) {
                res.json().then((data) => {
                    handleApiJson(data)
                    globalSnackSuccess.update(s => {
                        s.display = true;
                        s.text = S.SuccessfulLogin;
                    })
                    localStorage.token = data['token']
                    authStore.update(s => {
                        s.token = data['token']
                    })
                    fetchSelf()
                    setRedirect(true)
                })
            }

        })
    }

    useEffect(() => {
        UIStore.update(s => {
            s.pageTitle = S.signIn
        })

    }, [])

    const [redirect, setRedirect] = React.useState(false)

    if (redirect) {
        return <Redirect to='/new_test'/>
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    {S.signIn}
                </Typography>
                <form autocomplete="off" onSubmit={handleForm} className={classes.form}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label={S.username}
                        name="username"
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label={S.password}
                        type="password"
                        id="password"
                    />
                    <Button
                        type="submit"
                        fullWidth

                        variant="outlined"
                        color="primary"
                        className={classes.submit}
                    >
                        {S.signIn}
                    </Button>
                </form>
                <Link style={{textDecoration: 'none', color: theme.palette.text.primary}}
                      to='/forgot-password'><Button
                    fullWidth
                    variant="outlined"
                    color="secondary"
                    className={classes.submit}
                >Forgot Password</Button></Link>
            </div>
        </Container>
    )
}

export function ForgotPassword() {
    const classes = RegisterUseStyles();

    function handleForm(event) {
        event.preventDefault();
        const data = new FormData(event.target)
        fetch(process.env.REACT_APP_API_URL + 'api/v1/forgotpassword/', {
            body: data,
            method: "post"
        }).then((res) => {
            globalSnackSuccess.update(s => {
                s.display = true;
                s.text = "Reset email send successfully"
            })
        })
    }

    useEffect(() => {
        UIStore.update(s => {
            s.pageTitle = "Forgot password"
        })

    }, [])

    const [redirect, setRedirect] = React.useState(false)

    if (redirect) {
        return <Redirect to='/new_test'/>
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Enter your Email
                </Typography>
                <form autocomplete="off" onSubmit={handleForm} className={classes.form}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="user_or_email"
                        label="Username or Email"
                        name="user_or_email"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="outlined"
                        color="primary"
                        className={classes.submit}
                    >
                        {S.signIn}
                    </Button>
                </form>
            </div>
        </Container>
    )
}

export function ResetPassword(props) {
    const classes = RegisterUseStyles();

    function handleForm(event) {
        event.preventDefault();
        const data = new FormData(event.target)
        fetch(process.env.REACT_APP_API_URL + `api/v1/resetpassword/${props.code}/${props.user_id}/`, {
            body: data,
            method: "post"
        }).then((res) => {
            if (res.status === 400) {
                globalSnackError.update(s => {
                    s.display = true;
                    s.text = S.wrongCredInfo
                })
            } else if (res.status === 200) {
                res.json().then((data) => {
                    handleApiJson(data)
                    globalSnackSuccess.update(s => {
                        s.display = true;
                        s.text = "Password has been reset";
                    })
                    setRedirect(true)
                })
            }

        })
    }

    useEffect(() => {
        UIStore.update(s => {
            s.pageTitle = "Reset password"
        })

    }, [])

    const [redirect, setRedirect] = React.useState(false)

    if (redirect) {
        return <Redirect to='/login'/>
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Enter your new password
                </Typography>
                <form autocomplete="off" onSubmit={handleForm} className={classes.form}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label={S.password}
                        type="password"
                        id="password"
                    />
                    <Button
                        type="submit"
                        fullWidth

                        variant="outlined"
                        color="primary"
                        className={classes.submit}
                    >
                        Change password
                    </Button>
                </form>
            </div>
        </Container>
    )
}