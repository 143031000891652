import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {globalSnackError, globalSnackSuccess, UIStore} from "./stores";
import TextField from "@material-ui/core/TextField";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Card from "@material-ui/core/Card";
import {PlayArrow, PlayCircleFilled, Stop, Update} from "@material-ui/icons";
import Chip from "@material-ui/core/Chip";
import Icon from "@material-ui/core/Icon";
import {Link} from "react-router-dom";
import theme from "./theme";
import DialogContentText from "@material-ui/core/DialogContentText";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles({
    root: {
        minWidth: 300,
        minHeight: 235,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
        textTransform: "capitalize",
    },
    chip: {
        position: "absolute", top: 10, right: 15
    },
});

export default function TestItem(props) {
    const classes = useStyles();
    const [data, setData] = React.useState(props.data)

    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);

    const handleDeleteClickOpen = () => {
        setOpenDeleteDialog(true);
    };

    const handleDeleteClose = () => {
        setOpenDeleteDialog(false);
    };

    const [openResDialog, setOpenResDialog] = React.useState(false);

    const handleResClickOpen = () => {
        setOpenResDialog(true);
    };

    const handleResClose = () => {
        setOpenResDialog(false);
    };

    const [openChangeDialog, setOpenChangeDialog] = React.useState(false);
    const showTestResId = UIStore.useState(s => s.showTestResId)

    const handleChangeClickOpen = () => {
        setOpenChangeDialog(true);
    };

    const handleChangeClose = () => {
        setOpenChangeDialog(false);
    };

    function getStatusData(status) {
        return {
            "initialized": {
                "icon": <Icon>not_started</Icon>,
                "text": "Initialized",
            },
            "in_progress": {
                "icon": <Icon>hourglass_bottom</Icon>,
                "text": "In Progress",
            },
            "finished": {
                "icon": <Icon>done_all</Icon>,
                "text": "Finished",
            },
            "canceled": {
                "icon": <Icon>cancel</Icon>,
                "text": "Canceled",
            }
        }[status]
    }

    useEffect(() => {
        console.log(props.data.id, showTestResId)
        if (showTestResId && showTestResId === props.data.id) {
            setOpenResDialog(true)
            UIStore.update(s => {
                s.showTestResId = null
            })
        }
    }, [])

    function cancelItem() {
        handleDeleteClose()
        UIStore.update((s) => {
            s.loading = true
        })
        const url = process.env.REACT_APP_API_URL + `api/v1/tests/${data.id}/cancel/`
        fetch(url, {
            headers: {
                "Authorization": 'Token ' + localStorage.token
            },
            method: "post"
        }).then((res) => {
            UIStore.update((s) => {
                s.loading = false
            })
            if (res.status === 200) {
                globalSnackSuccess.update(s => {
                    s.display = true;
                    s.text = "Test canceled.";
                })
                res.json().then((data) => {
                    setData(data['data'])
                })

            } else {
                globalSnackError.update(s => {
                    s.display = true;
                    s.text = "Failed canceling this test.";
                })
            }
        })
    }

    const dialogs = (
        <div>
            <Dialog

                open={openResDialog}
                onClose={handleResClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">Test Results</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {data.type === 'final' && <div>
                            <Typography variant="body2" style={{fontSize: 15, fontWeight: "bold"}}>
                                Status: <span
                                style={{color: data.results.failed ? "#d32f2f" : "#388e3c"}}>{data.results.failed ? "Failed" : "Passed"}</span></Typography>
                            <Divider style={{
                                marginTop: 10,
                                marginBottom: 10,
                            }}/></div>}
                        <Typography variant="body2" style={{fontSize: 15}}>Total
                            Questions: {data.results.total_questions}</Typography>
                        <Typography variant="body2" style={{fontSize: 15}}>Total
                            Answers: {data.results.total_answered}</Typography>
                        <Typography variant="body2" style={{fontSize: 15, color: "#388e3c"}}>Right
                            Answers: {data.results.right_answers}/{data.results.total_answered}</Typography>
                        <Typography variant="body2" style={{fontSize: 15, color: "#d32f2f"}}>Wrong
                            Answers: {data.results.wrong_answers}/{data.results.total_answered}</Typography>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            <Dialog
                open={openDeleteDialog}
                onClose={handleDeleteClickOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle>{`Delete ${data.title} ?`}</DialogTitle>
            </Dialog>
            <Dialog open={openChangeDialog} onClose={handleChangeClose} aria-labelledby="form-dialog-title">
                <DialogTitle>Edit {data.title}</DialogTitle>
                <DialogContent>
                    <TextField
                        margin="dense"
                        label="Title"
                        type="text"
                        fullWidth
                        value={data.title}
                    />
                </DialogContent>
            </Dialog>
        </div>
    )

    return <div>
        {data &&
        <Card className={classes.root} variant={openDeleteDialog || openChangeDialog ? "elevation" : "outlined"}>
            <CardContent style={{position: "relative"}}>
                <Chip
                    icon={getStatusData(data.status)["icon"]}
                    label={getStatusData(data.status)["text"]}
                    className={classes.chip}
                    color={data.status === 'finished' ? "primary" : "inherit"}
                />
                <Typography style={{marginTop: 33}} className={classes.text} variant="subtitle1" component="h5"
                            gutterBottom>
                    {data.title}
                </Typography>
                <Typography className={classes.pos} color="textPrimary">
                    Test Type: {data.type}
                </Typography>
                <Typography className={classes.pos} color="textSecondary">
                    Question Type: {data.question_type.title}
                </Typography>
            </CardContent>
            <CardActions>
                {data && data.status === 'initialized' &&
                <Link style={{textDecoration: 'none', color: theme.palette.text.primary}}
                      to={`/tests/${data.id}/answer`}><Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    className={classes.button}
                    startIcon={<PlayCircleFilled/>}
                >
                    Start
                </Button></Link>}
                {data && data.results !== {} && data.status !== 'initialized' &&
                <Button
                    variant="outlined"
                    color="secondary"
                    size="large"
                    className={classes.button}
                    startIcon={<Icon>analytics</Icon>}
                    onClick={() => {
                        setOpenResDialog(true)
                    }}
                >
                    Results
                </Button>}

                {data && data.status === 'in_progress' &&
                <Link style={{textDecoration: 'none', color: theme.palette.text.primary}}
                      to={`/tests/${data.id}/answer`}><Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    className={classes.button}
                    startIcon={<PlayArrow/>}
                >
                    Resume
                </Button></Link>}
                {data && (data.status === 'in_progress' || data.status === 'initialized') && data.type === 'final' &&
                <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    style={{
                        color: "#d14242", borderColor: "#d14242"
                    }}
                    onClick={cancelItem}
                    className={classes.button}
                    startIcon={<Stop/>}
                >
                    Cancel
                </Button>}
                {data && data.status === 'in_progress' && data.type === 'training' && <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    style={{
                        color: "#d14242", borderColor: "#d14242"
                    }}
                    className={classes.button}
                    startIcon={<Update/>}
                    onClick={cancelItem}
                >
                    Reset
                </Button>}
            </CardActions>
            {dialogs}
        </Card>}
    </div>;
}