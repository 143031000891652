import {Store} from "pullstate";
import S from "./Translations";

export const globalSnackError = new Store({
    text: "",
    display: false
});
export const globalSnackSuccess = new Store({
    text: "",
    display: false
});

export const UIStore = new Store({
    pageTitle: S.siteTitle,
    loading: false,
    editQuestionReadOnly: false,
    openGlobalModal: null,
    editQuestionSlug: null,
    globalModalData: null,
    types: [],
    categories: [],
    mainActionButton: null,
    questionData: null,
    showTestResId: null
})


export const authStore = new Store({
    token: localStorage.token || null,
    self: {},
    is_admin: false
})




window.as = authStore