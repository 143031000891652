import React, {useEffect} from "react";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import {globalSnackError, UIStore} from "./stores";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import {convertIsoToReadable, fetchTypes, handleApiJson} from "./utils";
import S from "./Translations";

const useStyles = makeStyles((theme) => ({
    icon: {
        marginRight: theme.spacing(2),
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
    },
    card: {
        userSelect: "none",
        cursor: "pointer",
    },
    gridRoot: {
        justifyContent: 'center'
    }
}));

export default function NewEasyTest(props) {
    const types = UIStore.useState(s => s.types)
    const [step, setStep] = React.useState('select_type')
    const [categories, setCategories] = React.useState(null)
    const [selectedType, setSelectedType] = React.useState(0)
    const [selectedCategory, setSelectedCategory] = React.useState(0)

    function setType(id) {
        types.forEach(function (item, index) {
            if (item.id === id) {
                setSelectedType(item.id)
                fetchCategories(item.slug)
                setStep('select_category')
            }
        });
    }

    function fetchCategories(serach) {
        UIStore.update((s) => {
            s.loading = true
        })
        fetch(process.env.REACT_APP_API_URL + `api/v1/question_categories/?search=-${serach}`, {
            headers: {
                "Authorization": 'Token ' + localStorage.token
            },
            method: "get"
        }).then((res) => {
            UIStore.update((s) => {
                s.loading = false
            })
            if (res.status === 200) {
                res.json().then((data) => {
                    setCategories(data['data'])
                })
            }
        })
    }

    function addItem(type) {
        UIStore.update((s) => {
            s.loading = true
        })
        fetch(process.env.REACT_APP_API_URL + `api/v1/users/self/tests/`, {
            headers: {
                "Authorization": 'Token ' + localStorage.token,
                "Content-Type": "application/json"
            },
            method: "post",
            body: JSON.stringify({
                'title': convertIsoToReadable(new Date()),
                'type': type,
                'question_type_id': selectedType,
                'question_category_id': selectedCategory,
            })
        }).then((res) => {
            UIStore.update(s => {
                s.loading = false
            })
            if (res.status === 201) {
                res.json().then((data) => {
                    console.log(data)
                    props.history.push(`/tests/${data.data.id}/answer`)
                })

            } else {
                res.json().then((data) => {
                    handleApiJson(data)
                    globalSnackError.update(s => {
                        s.display = true;
                        s.text = data["errors"][0].message || data["errors"][0].error_type;
                    })
                })
            }
        })
    }

    const classes = useStyles();

    useEffect(() => {
        UIStore.update(s => {
            s.pageTitle = S.siteTitle
        })
        fetchTypes()
        fetchCategories()

    }, [])

    return <div>
        <Container style={{maxWidth: 'none'}} className={classes.paper} component="div" maxWidth="xs">
            {step === 'select_type' && <div>
                <Typography gutterBottom variant="h4">
                    Select your question type
                </Typography>
                <br/>
                <Grid className={classes.gridRoot} container spacing={3}>
                    {types && types.map(data => <Grid item key={data.id} xs={12} sm={6} md={4}> <Card onClick={() => {
                        setType(data.id)
                    }} className={classes.card} variant="outlined">
                        <CardContent>
                            <Typography className={classes.text} variant="subtitle1" component="h5">
                                {data.title}
                            </Typography>
                        </CardContent>
                    </Card></Grid>)}
                </Grid>
            </div>}
            {step === 'select_category' && categories && <div>
                <Typography gutterBottom variant="h4">
                    Select your question category
                </Typography>
                <br/>
                <Grid className={classes.gridRoot} container spacing={3}>
                    {categories && categories.map(data => <Grid item key={data.id} xs={12} sm={6} md={4}> <Card
                        onClick={() => {
                            setSelectedCategory(data.id)
                            setStep('select_test_type')
                        }} className={classes.card} variant="outlined">
                        <CardContent>
                            <Typography className={classes.text} variant="subtitle1" component="h5">
                                {data.title}
                            </Typography>
                        </CardContent>
                    </Card></Grid>)}
                </Grid>
            </div>}
            {step === 'select_test_type' && categories && <div>
                <Typography gutterBottom variant="h4">
                    Select your test type
                </Typography>
                <br/>
                <Grid className={classes.gridRoot} container spacing={3}>
                    <Grid item key={'training'} xs={12} sm={6} md={4}> <Card onClick={() => {
                        addItem('training')
                    }} className={classes.card} variant="outlined">
                        <CardContent>
                            <Typography className={classes.text} variant="subtitle1" component="h5">
                                Preparation
                            </Typography>
                        </CardContent>
                    </Card></Grid>
                    <Grid item key={'final'} xs={12} sm={6} md={4}> <Card onClick={() => {
                        addItem('final')
                    }} className={classes.card} variant="outlined">
                        <CardContent>
                            <Typography className={classes.text} variant="subtitle1" component="h5">
                                Final
                            </Typography>
                        </CardContent>
                    </Card></Grid>
                </Grid>
            </div>}

        </Container>
    </div>
}