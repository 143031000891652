import React, {useEffect} from "react";
import {globalSnackError, globalSnackSuccess, UIStore} from "./stores";
import history from "history/browser";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from "@material-ui/core/Typography";
import {isMobile} from "react-device-detect";
import {Divider} from "@material-ui/core";
import {mainActionButtonPress} from "./subjects";

const useStyles = makeStyles((theme) => ({
    // root: {
    //     display: 'flex',
    // },
    // toolbar: {
    //     paddingRight: 24, // keep right padding when drawer closed
    // },
    // toolbarIcon: {
    //     display: 'flex',
    //     alignItems: 'center',
    //     justifyContent: 'flex-end',
    //     padding: '0 8px',
    //     ...theme.mixins.toolbar,
    // },
    // appBar: {
    //     zIndex: theme.zIndex.drawer + 1,
    //     transition: theme.transitions.create(['width', 'margin'], {
    //         easing: theme.transitions.easing.sharp,
    //         duration: theme.transitions.duration.leavingScreen,
    //     }),
    //     borderStyle: "solid",
    //     borderWidth: ".5px",
    //     borderColor: "#adadad",
    //     boxShadow: "none",
    //     color: "black",
    //     backgroundColor: "white"
    // },
    // appBarShift: {
    //     marginLeft:  ,
    //     width: `calc(100% - ${drawerWidth}px)`,
    //     transition: theme.transitions.create(['width', 'margin'], {
    //         easing: theme.transitions.easing.sharp,
    //         duration: theme.transitions.duration.enteringScreen,
    //     }),
    // },
    // menuButton: {
    //     marginRight: 36,
    // },
    // menuButtonHidden: {
    //     display: 'none',
    // },
    // title: {
    //     flexGrow: 1,
    // },
    // drawerPaper: {
    //     position: 'relative',
    //     whiteSpace: 'nowrap',
    //     width: drawerWidth,
    //     transition: theme.transitions.create('width', {
    //         easing: theme.transitions.easing.sharp,
    //         duration: theme.transitions.duration.enteringScreen,
    //     }),
    // },
    // drawerPaperClose: {
    //     overflowX: 'hidden',
    //     transition: theme.transitions.create('width', {
    //         easing: theme.transitions.easing.sharp,
    //         duration: theme.transitions.duration.leavingScreen,
    //     }),
    //     width: theme.spacing(7),
    //     [theme.breakpoints.up('sm')]: {
    //         width: theme.spacing(9),
    //     },
    // },
    // appBarSpacer: theme.mixins.toolbar,
    // content: {
    //     flexGrow: 1,
    //     height: '100vh',
    //     overflow: 'auto',
    // },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        marginBottom: "60px", paddingBottom: "61px"
    },
    paper2: {
        display: "flex",
        minWidth: "100%",
        marginTop: "55px",
        minHeight: "calc(100vh - 56px)",
        alignItems: "center",
        paddingBottom: "40px",
        backgroundSize: "224px",
        justifyContent: "center",
        backgroundColor: theme.palette.type === "light" ? "#ffffffe0" : "#2f2f2f",
        backgroundImage: "url(/odt_logo.jpg)",
        backgroundRepeat: "repeat",
        backgroundBlendMode: theme.palette.type === "light" ? "overlay" : "darken"
    },
    picture: {
        marginBottom: 15
    },
    fixedHeight: {
        height: 240,
    },
    choice: {
        marginBottom: 15
    },
    pagination: {},
    rootNav: {
        width: "100%",
        position: "fixed",
        bottom: "0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "60px",
        borderStyle: "solid",
        borderWidth: ".5px",
        borderColor: "#c3c3c3",
        background: "white",
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    fabStyle: {
        position: "fixed",
        bottom: 75,
        right: 18,
    },
    helpIcon: {
        position: "relative",
        marginRight: "9px", fontSize: "25px"
    },
    formRoot: {},
    formControl: {
        //margin: theme.spacing(3),
        width: "85%",
        fontSize: "15px",
        lineHeight: 0,
        display: "flex",
        justifyContent: "center",
    },
    formLabel: {
        width: "100%",
        display: "flex",
        textAlign: "left",
        alignItems: "center",
        lineHeight: "30px",
        justifyContent: "start",
        whiteSpace: "pre-wrap",
        overflowWrap: "anywhere",
        fontSize: "1.2rem", fontWeight: 500
    },
    button: {
        margin: theme.spacing(1, 1, 0, 0),
        width: "100%"
    },

    buttonC: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    }
}));

export function AnswerTest(props) {
    const classes = useStyles()
    const [data, setData] = React.useState(null)

    const [type, setType] = React.useState(null)

    useEffect(() => {
        fetchData()
    }, [props.test_id])

    function fetchData() {

        UIStore.update((s) => {
            s.loading = true
        })
        fetch(process.env.REACT_APP_API_URL + `api/v1/tests/${props.test_id}/next-question/`,
            {
                headers: {
                    "Authorization": 'Token ' + localStorage.token
                },
                method: "get"
            }).then((res) => {
            UIStore.update(s => {
                s.loading = false
            })
            if (res.status === 200) {
                res.json().then(data => {
                    setData(data['data'])
                    setType(data['data']['type'])
                })
            } else {
                globalSnackError.update(s => {
                    s.display = true;
                    s.text = "Failed fetching this test.";
                })
                history.back()
            }
        })
    }

    useEffect(() => {
        UIStore.update(s => {
            s.mainActionButton = 'quit_test'
        })
        const uns = mainActionButtonPress.subscribe({
            next: (d) => {
                if (d === 'quit_test') {
                    history.back()
                }
            }
        })
        return () => {
            UIStore.update(s => {
                s.mainActionButton = null
            })
            uns.unsubscribe()
        };
    }, []);

    const [answerValue, setAnswerValue] = React.useState(null);
    const [showCorrect, setShowCorrect] = React.useState(null);

    const handleAnswerChange = (event) => {
        setAnswerValue(parseInt(event.target.value));
        setShowCorrect(true)
    };

    function submitAnswer() {
        UIStore.update((s) => {
            s.loading = true
        })
        let url = ''
        url = process.env.REACT_APP_API_URL + `api/v1/answered-questions/${data.id}/answer/`
        fetch(url, {
            headers: {
                "Authorization": 'Token ' + localStorage.token,
                "Content-Type": "application/json"
            },
            method: "post",
            body: JSON.stringify({
                choice_id: answerValue,
                question_id: data['question']['id']
            })
        }).then((res) => {
            resetAnswer()
            if (res.status === 200) {
                UIStore.update(s => {
                    s.loading = false
                })
                res.json().then((data) => {
                    setData(data['data'])
                })
            } else {
                res.json().then((errorData) => {

                    if (errorData["errors"][0].error_type === "noQuestionsFound") {
                        props.history.push('tests')
                        globalSnackSuccess.update(s => {
                            s.display = true;
                            s.text = "No more questions for you to answer."
                        })
                    } else if (errorData["errors"][0].error_type === "testIsCanceledOrFinished") {
                        props.history.push('tests')
                        globalSnackSuccess.update(s => {
                            s.display = true;
                            s.text = "Thank you for your time"
                        })
                        if (type === 'final') {
                            UIStore.update(s => {
                                s.showTestResId = parseInt(props.test_id)
                            })
                        }

                    } else {
                        globalSnackError.update(s => {
                            s.display = true;
                            s.text = errorData["errors"][0].message || errorData["errors"][0].error_type;
                        })
                    }
                })
            }
        })
    }

    const [pictureW, setPictureW] = React.useState(null)
    const [pictureH, setPictureH] = React.useState(null)

    function onImgLoad(img) {
        setPictureW(img.target.offsetWidth)
        setPictureH(img.target.offsetHeight)
    }


    function resetAnswer() {
        setAnswerValue(null)
        setShowCorrect(false)
    }


    return <div>
        {data && <Paper className={classes.paper2}>

            <form onSubmit={e => {
                e.preventDefault()
            }} style={{
                width: isMobile ? "100%" : "unset",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minWidth: "40vw",
            }}>
                <FormControl component="fieldset"
                             className={classes.formControl}>
                    <Typography variant="body1" gutterBottom className={classes.formLabel} component="legend">
                        <div dangerouslySetInnerHTML={{__html: data.question.text}}></div>
                    </Typography>
                    {data.question && data.question.image && <div className={classes.picture}
                                                                  style={isMobile ? {maxWidth: "90vw"}:{maxWidth: "30vw"}}>
                        <img style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                        }} onLoad={onImgLoad} alt='Test Image' src={data.question.image}/>
                    </div>}
                    <br/><br/>
                    <Divider/>
                    {(type === 'final' || !showCorrect) &&
                    <RadioGroup onChange={handleAnswerChange} value={answerValue} aria-label="quiz" name="quiz">
                        <FormControlLabel style={{marginTop: "15px"}} className={classes.choice}
                                          value={data.question.choice_1_id} control={<Radio/>}
                                          label={data.question.choice_1_text}/>
                        <FormControlLabel
                            className={classes.choice} value={data.question.choice_2_id} control={<Radio/>}
                            label={data.question.choice_2_text}/>
                        <FormControlLabel className={classes.choice} value={data.question.choice_3_id}
                                          control={<Radio/>}
                                          label={data.question.choice_3_text}/>
                        <FormControlLabel className={classes.choice} value={data.question.choice_4_id}
                                          control={<Radio/>}
                                          label={data.question.choice_4_text}/>
                    </RadioGroup>}
                    {type === 'training' && showCorrect &&
                    <RadioGroup onChange={handleAnswerChange} value={answerValue} aria-label="quiz" name="quiz">
                        <FormControlLabel className={classes.choice} value={data.question.choice_1_id}
                                          control={<Radio/>}
                                          label={data.question.choice_1_text}
                                          style={data.question.choice_1_correct_answer ? {
                                              color: '#009d00',
                                              fontWeight: 'bold !important'
                                          } : {color: '#c40000'}}
                        />
                        <FormControlLabel
                            style={data.question.choice_2_correct_answer ? {
                                color: '#009d00',
                                fontWeight: 'bold !important'
                            } : {color: '#c40000'}}
                            className={classes.choice} value={data.question.choice_2_id} control={<Radio/>}
                            label={data.question.choice_2_text}/>
                        <FormControlLabel className={classes.choice} value={data.question.choice_3_id}
                                          control={<Radio/>}
                                          style={data.question.choice_3_correct_answer ? {
                                              color: '#009d00',
                                              fontWeight: 'bold !important'
                                          } : {color: '#c40000'}}
                                          label={data.question.choice_3_text}/>
                        <FormControlLabel className={classes.choice} value={data.question.choice_4_id}
                                          control={<Radio/>}
                                          style={data.question.choice_4_correct_answer ? {
                                              color: '#009d00',
                                              fontWeight: 'bold !important'
                                          } : {color: '#c40000'}}
                                          label={data.question.choice_4_text}/>
                    </RadioGroup>}
                    <div className={classes.buttonC}>
                        {answerValue &&
                        <Button style={{width: "30%"}} onClick={resetAnswer} variant="contained" color="secondary"
                                className={classes.button}>
                            {answerValue ? "Reset" : "Skip"}
                        </Button>}
                        <Button style={{width: answerValue ? "70%" : "100%"}} onClick={submitAnswer} variant="contained"
                                color="primary" className={classes.button}>
                            {answerValue ? "Submit" : "Skip"}
                        </Button>
                    </div>

                </FormControl>
            </form>
        </Paper>}
    </div>
}
