import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {globalSnackError, globalSnackSuccess} from "./stores";
import {handleApiJson} from "./utils";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Card from "@material-ui/core/Card";

const useStyles = makeStyles({
    root: {
        minWidth: 300,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },

});

export default function CategoryTypeCard(props) {
    const classes = useStyles();
    const [data, setData] = React.useState(props.data)

    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);

    const handleDeleteClickOpen = () => {
        setOpenDeleteDialog(true);
    };

    const handleDeleteClose = () => {
        setOpenDeleteDialog(false);
    };

    const [openChangeDialog, setOpenChangeDialog] = React.useState(false);

    const handleChangeClickOpen = () => {
        setOpenChangeDialog(true);
    };

    const handleChangeClose = () => {
        setOpenChangeDialog(false);
    };

    function deleteItem() {
        handleDeleteClose()
        const url = process.env.REACT_APP_API_URL + `api/v1/${props.is_type ? 'question_types' : 'question_categories'}/${data.slug}/`
        fetch(url, {
            headers: {
                "Authorization": 'Token ' + localStorage.token
            },
            method: "delete"
        }).then((res) => {
            if (res.status === 204) {
                globalSnackSuccess.update(s => {
                    s.display = true;
                    s.text = "This type is gone.";
                })
                setData(null)
            } else {
                globalSnackError.update(s => {
                    s.display = true;
                    s.text = "Failed deleting this type.";
                })
            }
        })
    }

    const [newItemTitle, setNewItemTitle] = React.useState(props.data.title);
    const [newItemFrTitle, setNewItemFrTitle] = React.useState(props.data.translations.fr_title);
    const [newItemSlug, setNewItemSlug] = React.useState(props.data.slug);
    const [newCategoryFinalQuestions, setNewCategoryFinalQuestions] = React.useState(props.data.final_questions ? props.data.final_questions.toString() : '');
    const [newCategoryFailTrigger, setNewCategoryFailTrigger] = React.useState(props.data.fail_trigger ? props.data.fail_trigger.toString() : '');

    function changeItem() {
        fetch(process.env.REACT_APP_API_URL + `api/v1/${props.is_type ? 'question_types' : 'question_categories'}/${data.slug}/`, {
            headers: {
                "Authorization": 'Token ' + localStorage.token,
                "Content-Type": "application/json"
            },
            method: "put",
            body: JSON.stringify({
                'title': newItemTitle,
                'slug': newItemSlug,
                'translations': {
                    'fr_title': newItemFrTitle
                },
                'final_questions': parseInt(newCategoryFinalQuestions),
                'fail_trigger': parseInt(newCategoryFailTrigger)
            })
        }).then((res) => {
            handleChangeClose()
            if (res.status === 200) {
                res.json().then((data) => {
                    handleApiJson(data)
                    setData(data['data'])
                })
            } else {

            }
        })
    }

    const dialogs = (
        <div>
            <Dialog
                open={openDeleteDialog}
                onClose={handleDeleteClickOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle>{`Delete ${data ? data.title : ''} ?`}</DialogTitle>
                <DialogActions>
                    <Button onClick={handleDeleteClose} autoFocus color="primary">
                        No
                    </Button>
                    <Button onClick={deleteItem} color="secondary">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openChangeDialog} onClose={handleChangeClose} aria-labelledby="form-dialog-title">
                <DialogTitle>Edit {data ? data.title : ''}</DialogTitle>
                <DialogContent>
                    <TextField
                        margin="dense"
                        label="Title"
                        type="text"
                        fullWidth
                        value={newItemTitle}
                        onChange={e => {
                            setNewItemTitle(e.target.value)
                        }}
                    />
                    <TextField
                        margin="dense"
                        label="French Title"
                        type="text"
                        fullWidth
                        value={newItemFrTitle}
                        onChange={e => {
                            setNewItemFrTitle(e.target.value)
                        }}
                    />
                    <TextField
                        margin="dense"
                        label="Slug"
                        type="text"
                        fullWidth
                        value={newItemSlug}
                        onChange={e => {
                            setNewItemSlug(e.target.value)
                        }}
                    />
                    {!props.is_type && <TextField
                        margin="dense"
                        label="Final Questions"
                        type="text"
                        fullWidth
                        value={newCategoryFinalQuestions}
                        onChange={e => {
                            setNewCategoryFinalQuestions(e.target.value)
                        }}
                    />}
                    {!props.is_type && <TextField
                        margin="dense"
                        label="Fail Trigger"
                        type="text"
                        fullWidth
                        value={newCategoryFailTrigger}
                        onChange={e => {
                            setNewCategoryFailTrigger(e.target.value)
                        }}
                    />}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleChangeClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={changeItem} color="secondary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )

    return (<div>
            {data &&
            <Card className={classes.root} variant={openDeleteDialog || openChangeDialog ? "elevation" : "outlined"}>
                <CardContent>
                    <Typography className={classes.text} variant="subtitle1" component="h5" gutterBottom>
                        {data.title}
                    </Typography>
                    <Typography className={classes.pos} color="textPrimary">
                        {data.translations.fr_title}
                    </Typography>
                    <Typography className={classes.pos} color="textSecondary">
                        {data.slug}
                    </Typography>
                    {!props.is_type && <Typography className={classes.pos} variant="subtitle1" color="textSecondary">
                        Final Questions: {data.final_questions}
                    </Typography>}
                    {!props.is_type && <Typography className={classes.pos} variant="subtitle1" color="textSecondary">
                        Fail Trigger: {data.fail_trigger}
                    </Typography>}
                </CardContent>
                <CardActions>
                    <Button onClick={handleDeleteClickOpen} size="small">Delete</Button>
                    <Button onClick={handleChangeClickOpen} size="small">Edit</Button>
                </CardActions>
                {dialogs}
            </Card>}
        </div>
    );
}