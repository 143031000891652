import React, {useEffect} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {UIStore} from "./stores";
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import {isMobile, isTablet} from "react-device-detect";
import {Card, CardHeader, CardMedia, Paper} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import CardContent from "@material-ui/core/CardContent";
import {red} from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
    icon: {
        marginRight: theme.spacing(2),
    },
    Postcard: {
        zIndex: 1,
        fontFamily: "sans-serif",
        width: "100%",
        // height: "10vh" ? isTablet : ["30vh" ? isMobile : "50vh"],
        height: isTablet ? "100vh" : [isMobile ? "100vh" : "100vh"],
        padding: theme.spacing(5, 5, 5, 5),
        display: "inline-block",
        flexWrap: "wrap",
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.7)), url("home-page-back.jpg")`,
        // backgroundSize: "cover",
        backgroundSize: "cover",
        // backgroundPosition:"center",
        backgroundPosition: isMobile ? `bottom center`:"cover",
        // backgroundAttachment: isMobile ? "fixed" : "scroll"
        backgroundAttachment: isMobile ? "scroll" : "fixed"

        // let ImageStyle = {width: "100%", height: "50vh"}
        //
        // if (isTablet) {
        //     ImageStyle = {width: "100%", height: "30vh"}
        // }
        //
        // if (isMobile) {
        //     ImageStyle = {width: "100%", height: "30vh"}
        // }
    },
    // card: {
    //     height: '100vh',
    //     display: 'flex',
    //     flexDirection: 'column',
    // },
    t_background: {
        backgroundColor: `rgba(255, 255, 255, 0.1)`,
        zIndex: 2,
        borderRadius: "20px",
        marginTop: "10px"
    },
    heroTextBox: {
        fontSize: '19px',
        padding: `10px 0 0 0 `,
        fontFamily: 'Open Sans Condensed',
        marginTop: isMobile === true ? "40%" : "20%",
        position: "center",
        justifyContent: "center",
        backgroundSize: 'cover',
        // backgroundPosition: 'center',
        color: "white",
        backgroundAttachment: 'scroll',
        letterSpacing: "9px"
    },
    heroContent: {
        padding: theme.spacing(8)
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    RegisterButton: {
        backgroundColor: "#DD7230",
        "&:hover": {
            color: "white",
            backgroundColor: "#923800",
        }
    },

    LoginButton: {
        color: "white",
        borderColor: "#DD7230",
        "&:hover": {
            borderColor: "white"
        }
    },
    cardGrid: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(8),
    },

    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
    footer: {
        padding: theme.spacing(2),
    },
    paper: {
        margin: theme.spacing(2)
    }

}));

export default function HomePage(props) {
    console.log(isMobile)
    console.log(isTablet)
    const classes = useStyles();
    useEffect(() => {
        UIStore.update(s => {
            s.pageTitle = "National Truck Driving School"
        })

    }, [])
    let ImageStyle = {width: "100%", height: "50vh"}

    if (isTablet) {
        ImageStyle = {width: "100%", height: "30vh"}
    }

    if (isMobile) {
        ImageStyle = {width: "100%", height: "30vh"}
    }

    return (
        <React.Fragment>
            <CssBaseline/>
            <main>
                <div className={classes.Postcard}>
                    <div className={classes.t_background}>
                        <div className={classes.heroTextBox}>
                            <div><h1><span
                                style={{
                                    margin: "0px",
                                    padding: "0px",
                                    color: "#DD7230"
                                }}
                            >W</span>elcome</h1></div>
                            <div><h2>to <span
                                style={{
                                    margin: "0px",
                                    padding: "0px",
                                    color: "#DD7230"
                                }}>N</span>ational Truck Driving school Test Site</h2></div>
                        </div>
                        <div className={classes.heroButtons}>
                            <Grid container spacing={2} justify="center">
                                {localStorage.token && <Grid item>
                                    <Button onClick={() => {
                                        props.history.push('/new_test')
                                    }} variant="contained">
                                        Start Testing
                                    </Button>
                                </Grid>}
                                {!localStorage.token && <Grid item>
                                    <Button onClick={() => {
                                        props.history.push('/register')
                                    }} variant="contained" className={classes.RegisterButton}>
                                        Register
                                    </Button>
                                </Grid>}
                                {!localStorage.token && <Grid item>
                                    <Button onClick={() => {
                                        props.history.push('/login')
                                    }} variant="outlined" className={classes.LoginButton}>
                                        login
                                    </Button>
                                </Grid>}
                            </Grid>
                        </div>
                    </div>
                </div>

                <div className={classes.heroContent}>
                    {/*<Container maxWidth="sm">*/}


                    {/*</Container>*/}

                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Card className={classes.root}>
                                <CardHeader
                                    avatar={
                                        <Avatar aria-label="recipe" className={classes.avatar}>
                                            N
                                        </Avatar>
                                    }
                                    action={
                                        <IconButton aria-label="settings">

                                        </IconButton>
                                    }
                                    title="With hundreds online questions for drivers"
                                    subheader="only 100 C$ for National Truck students"
                                />
                                <CardMedia
                                    className={classes.media}
                                    image="/static/images/cards/paella.jpg"
                                    title="Paella dish"
                                />
                                <CardContent>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        You can register now and after confirmation of your registeration,
                                        start testing yousrself
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Card className={classes.root}>
                                <CardHeader
                                    avatar={
                                        <Avatar aria-label="recipe" className={classes.avatar}>
                                            R
                                        </Avatar>
                                    }
                                    action={
                                        <IconButton aria-label="settings">

                                        </IconButton>
                                    }
                                    title="With hundreds online questions for drivers"
                                    subheader="only 100 C$ for National Truck students"
                                />
                                <CardMedia
                                    className={classes.media}
                                    image="/static/images/cards/paella.jpg"
                                    title="Paella dish"
                                />
                                <CardContent>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        You can register now and after confirmation of your registeration,
                                        start testing yousrself
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
                {/*<Container className={classes.cardGrid} maxWidth="md">*/}
                {/*    <Carousel>*/}
                {/*        <img style={ImageStyle}*/}
                {/*             src="./1.webp"/>*/}
                {/*        <img style={ImageStyle}*/}
                {/*             src="2.webp"/>*/}
                {/*        <img style={ImageStyle}*/}
                {/*             src="3.webp"/>*/}
                {/*    </Carousel>*/}
                {/*</Container>*/}
            </main>
            {/* Footer */}
            <footer className={classes.footer}>
                <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
                    National Truck Driving School © 2021 / 514937.2888
                </Typography>
            </footer>
            {/* End footer */}
        </React.Fragment>
    );
}