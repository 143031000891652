import {globalSnackError, UIStore} from "./stores";
import React, {useEffect} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import {convertIsoToReadable, handleApiJson} from "./utils";
import {makeStyles} from "@material-ui/core/styles";
import {isMobile} from "react-device-detect";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {FormControl} from "@material-ui/core";
import TestItem from "./TestItem";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import {mainActionButtonPress} from "./subjects";
import S from "./Translations";
import Icon from "@material-ui/core/Icon";
import {Pagination} from "@material-ui/lab";

const TestsStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
    },
    pagination: {
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        marginTop: "30px"
    },
    formControl: {
        minWidth: 120,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        backgroundColor: theme.palette.secondary.main,
        top: 10,
        left: "0px",
        right: "0px",
        width: "100px",
        height: "100px",
        margin: "auto",
        zIndex: 100,
        position: "absolute",
        fontSize: "50px",
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    fab: {
        bottom: "45px", right: "40px", position: "fixed", zIndex: 5
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 16,
        textTransform: "capitalize",
    },
    pos: {
        marginBottom: 12,
    },
    id: {
        position: "absolute", top: 10, left: 15
    },
    chip: {
        position: "absolute", top: 10, right: 15
    },
    root: {
        position: 'relative',
        paddingTop: 50,
        minWidth: isMobile ? "100%" : "400px",
        maxWidth: isMobile ? "none" : "500px"
    },
}));

export function Tests(props) {
    const classes = TestsStyles()

    useEffect(() => {
        UIStore.update(s => {
            s.mainActionButton = 'test_new'
        })
        UIStore.update(s => {
            s.pageTitle = "Final Tests"
        })
        const uns = mainActionButtonPress.subscribe({
            next: (d) => {
                if (d === 'tests_new') {
                    handleClickOpen()
                }
            }
        })
        return () => {
            UIStore.update(s => {
                s.mainActionButton = null

            })
            UIStore.update(s => {
                s.pageTitle = S.siteTitle
            })
            uns.unsubscribe()
        };
    }, []);

    const [data, setData] = React.useState(null)

    useEffect(() => {
        fetchData('final')

    }, [props.user_id])

    const [typeValue, setTypeValue] = React.useState('final');

    const handleTypeChange = (event) => {
        setTypeValue(event.target.value);
    };

    const [totalPages, setTotalPages] = React.useState(0);
    const [currentPage, setCurrentPage] = React.useState(0);
    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage)
        fetchData(type, newPage)
    };

    const [QuestionTypeValue, setQuestionTypeValue] = React.useState(null);
    const [title, setTitle] = React.useState(null);

    const handleQuestionTypeValueChange = (event) => {
        setQuestionTypeValue(parseInt(event.target.value));
    };

    function fetchData(type, page = 1, page_size = 12) {
        if (!props.user_id) {
            return
        }
        UIStore.update((s) => {
            s.loading = true
        })
        fetch(process.env.REACT_APP_API_URL + `api/v1/users/${props.user_id}/tests/?` + new URLSearchParams({
            type: type,
            page: page,
            page_size: page_size
        }),
            {
                headers: {
                    "Authorization": 'Token ' + localStorage.token
                },
                method: "get"
            }).then((res) => {
            UIStore.update(s => {
                s.loading = false
            })
            if (res.status === 200) {
                res.json().then(data => {
                    setData(data['data'])
                    setTotalPages(data['pagination']['pages_count'])
                    setCurrentPage(data['pagination']['current_page'])
                })
            } else {
                globalSnackError.update(s => {
                    s.display = true;
                    s.text = "Failed fetching tests.";
                })
                //history.back()
            }
        })
    }

    function addItem() {
        if (!QuestionTypeValue) {
            globalSnackError.update(s => {
                s.display = true;
                s.text = "Please select a question type first.";
            })
            return
        }
        handleClose()
        UIStore.update((s) => {
            s.loading = true
        })
        fetch(process.env.REACT_APP_API_URL + `api/v1/users/${props.user_id}/tests/`, {
            headers: {
                "Authorization": 'Token ' + localStorage.token,
                "Content-Type": "application/json"
            },
            method: "post",
            body: JSON.stringify({
                'title': title,
                'type': typeValue,
                'question_type_id': QuestionTypeValue,
            })
        }).then((res) => {
            UIStore.update(s => {
                s.loading = false
            })

            if (res.status === 201) {
                res.json().then((data) => {
                    console.log(data['data']['type'])
                    setType(data['data']['type'])
                    UIStore.update(s => {
                        s.pageTitle = data['data']['type'] + " Tests"
                    })
                    fetchData(data['data']['type'])
                })

            } else {
                res.json().then((data) => {
                    handleApiJson(data)
                    globalSnackError.update(s => {
                        s.display = true;
                        s.text = data["errors"][0].message || data["errors"][0].error_type;
                    })
                })
            }
        })
    }

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setTitle(convertIsoToReadable(new Date()))
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [type, setType] = React.useState('final')

    const types = UIStore.useState(s => s.types)

    return <Container style={{maxWidth: 'none'}} className={classes.paper} component="div" maxWidth="xs">
        <Dialog fullWidth={isMobile} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">New {props.is_type ? 'Type' : 'Category'}</DialogTitle>
            <DialogContent>
                <FormControl fullWidth margin="normal">
                    <TextField
                        margin="normal"
                        label="Title"
                        type="text"
                        fullWidth
                        value={title}
                        onChange={e => {
                            setTitle(e.target.value)
                        }}
                    />
                    <br/>
                    <FormLabel component="legend" color="secondary">Test Type</FormLabel>
                    <RadioGroup aria-label="test_type" name="type" value={typeValue} onChange={handleTypeChange}>
                        <FormControlLabel value="final" control={<Radio/>} label="Final"/>
                        <FormControlLabel value="training" control={<Radio/>} label="Training"/>
                    </RadioGroup>
                    <br/>
                    <FormLabel component="legend" color="secondary">Question Types</FormLabel>
                    <RadioGroup aria-label="question_type" name="question_type_id" value={QuestionTypeValue}
                                onChange={handleQuestionTypeValueChange}>
                        {types.map((t) => <FormControlLabel key={t.slug} value={t.id} control={<Radio/>}
                                                            label={t.title}/>)}
                    </RadioGroup>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={addItem} color="primary">
                    Add
                </Button>
            </DialogActions>
        </Dialog>
        {type === 'final' && <Fab color="primary" onClick={() => {
            setType('training')
            UIStore.update(s => {
                s.pageTitle = 'Training Tests'
            })
            fetchData('training')
        }} className={classes.fab} variant="extended">
            <Icon className={classes.extendedIcon}>model_training</Icon>
            Show training tests
        </Fab>}
        {type === 'training' && <Fab color="secondary" onClick={() => {
            setType('final')
            UIStore.update(s => {
                s.pageTitle = 'Final Tests'
            })
            fetchData('final')
        }} className={classes.fab} variant="extended">
            <Icon className={classes.extendedIcon}>fact_check</Icon>
            Show final tests
        </Fab>}
        <Grid container spacing={3}>
            {data && data.map(item => <Grid key={item.id} item md={6} xs={12} lg={4}><TestItem key={item.id}
                                                                                               data={item}/></Grid>)}
        </Grid>
        {totalPages > 1 &&
        <Pagination className={classes.pagination} count={totalPages} page={currentPage} color="secondary"
                    onChange={handleChangePage}/>}

        <br/><br/><br/><br/><br/><br/><br/><br/>

    </Container>
}