import {authStore, globalSnackError, globalSnackSuccess, UIStore} from "./stores";
import React, {useEffect} from "react";
import {handleApiJson} from "./utils";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {FilterList} from "@material-ui/icons";
import {Redirect} from "react-router-dom";
import {Pagination} from "@material-ui/lab";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import {editQuestionSubject, mainActionButtonPress} from "./subjects";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import {isMobile} from "react-device-detect";
import MenuItem from "@material-ui/core/MenuItem";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Fab from "@material-ui/core/Fab";

const TypesAdminUseStyles = makeStyles((theme) => ({
    pagination: {
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        marginTop: "30px"
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
    },
    formControl: {
        marginRight: theme.spacing(3),
        minWidth: 120,
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    fab: {
        bottom: "45px", right: "40px", position: "fixed"
    }
}));

const QuestionCardUseStyles = makeStyles((theme) => ({
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    text: {
        maxHeight: "100px",
        minHeight: "100px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    id: {
        position: "absolute", top: 10, left: 15
    },
    root: {
        position: 'relative'
    },
}));

export default function QuestionCard(props) {
    const [data, setData] = React.useState(props.data)

    const [redirect, setRedirect] = React.useState(null)

    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);

    const classes = QuestionCardUseStyles()

    const handleDeleteClickOpen = () => {
        setOpenDeleteDialog(true);
    };

    const handleDeleteClose = () => {
        setOpenDeleteDialog(false);
    };


    useEffect(() => {
        const uns = editQuestionSubject.subscribe({
            next: (d) => {
                if (d.slug === data.slug) {
                    setData(d)
                }
            }
        })
        return () => {
            uns.unsubscribe()
        };
    }, []);

    function deleteItem() {
        handleDeleteClose()
        const url = process.env.REACT_APP_API_URL + `api/v1/questions/${data.slug}/`
        fetch(url, {
            headers: {
                "Authorization": 'Token ' + localStorage.token
            },
            method: "delete"
        }).then((res) => {
            if (res.status === 204) {
                globalSnackSuccess.update(s => {
                    s.display = true;
                    s.text = "This questions is gone.";
                })
                setDisplay(false)
            } else {
                globalSnackError.update(s => {
                    s.display = true;
                    s.text = "Failed deleting this type.";
                })
            }
        })
    }

    const [display, setDisplay] = React.useState(true)

    if (redirect) {
        return <Redirect to={redirect}/>
    }
    const dialog = (
        <Dialog
            open={openDeleteDialog}
            onClose={handleDeleteClickOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle>{`Delete question ${data.slug} ?`}</DialogTitle>
            <DialogActions>
                <Button onClick={handleDeleteClose} autoFocus color="primary">
                    No
                </Button>
                <Button onClick={deleteItem} color="secondary">
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    )


    return (<div>
            {data && display &&
            <Card className={classes.root} variant={openDeleteDialog ? "elevation" : "outlined"}>
                <CardContent>
                    <Typography className={classes.text} variant="subtitle1" component="h5" gutterBottom>
                        <div dangerouslySetInnerHTML={{__html: data.text}}></div>
                    </Typography>
                    <Typography className={classes.pos} color="textSecondary">
                        {data.type.title} | {data.category.title}
                    </Typography>
                    <Typography className={classes.id} color="textSecondary">
                        #{data.id}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button onClick={handleDeleteClickOpen} size="small">Delete</Button>
                    <Button onClick={() => {
                        UIStore.update(s => {
                            s.questionData = data
                        })
                        props.history.push(`/questions/${data.slug}/view`)
                    }} size="small">View</Button>
                    <Button onClick={() => {
                        UIStore.update(s => {
                            s.questionData = data
                        })
                        props.history.push(`/questions/${data.slug}/edit`)
                    }} size="small">Edit</Button>
                </CardActions>
                {dialog}
            </Card>}
        </div>
    );
}

export function QuestionsAdmin(props) {

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setOpenSpeedDial(false)
    };

    const handleCloseApply = () => {
        setOpen(false);
        setOpenSpeedDial(false)
        fetchList()
    };

    const handleCloseReset = () => {
        setSelectedCategory(0)
        setSelectedType(0)
        setSearch(null)
        setOpen(false);
        setOpenSpeedDial(false)
    };

    useEffect(() => {
        fetchList()
    }, []);

    useEffect(() => {
        setTypesList(null)
        fetchList()
    }, [props.is_type])

    const [openSpeedDial, setOpenSpeedDial] = React.useState(false);


    const handleOpenSpeedDial = () => {
        setOpenSpeedDial(true);
        setBackdropZIndex(true)
    };

    const handleCloseSpeedDial = () => {
        setOpenSpeedDial(false);
        setTimeout(() => {
            setBackdropZIndex(false)
        }, 500)
    };

    useEffect(() => {
        UIStore.update(s => {
            s.pageTitle = "Questions"
        })

    }, [])


    useEffect(() => {
        UIStore.update(s => {
            s.mainActionButton = 'questions_filter'
        })
        const uns = mainActionButtonPress.subscribe({
            next: (d) => {
                if (d === 'questions_filter') {
                    handleClickOpen()
                }
            }
        })
        return () => {
            UIStore.update(s => {
                s.mainActionButton = null
            })
            uns.unsubscribe()
        };
    }, []);

    const isAdmin = authStore.useState(s => s.is_admin);
    const [typesList, setTypesList] = React.useState(null);
    const [totalPages, setTotalPages] = React.useState(0);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [redirect, setRedirect] = React.useState(null)
    const [backdropZIndex, setBackdropZIndex] = React.useState(null)

    const [search, setSearch] = React.useState('');
    const [selectedType, setSelectedType] = React.useState(0);
    const [selectedCategory, setSelectedCategory] = React.useState(0);

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage)
        fetchList(newPage)
    };

    const handleChangeRowsPerPage = (event) => {
        console.log(event.target.value, parseInt(event.target.value, 10))
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
        fetchList()
    };

    const types = UIStore.useState(s => s.types)
    const categories = UIStore.useState(s => s.categories)

    const classes = TypesAdminUseStyles();

    function fetchList(page = 1, page_size = 12) {
        let url = process.env.REACT_APP_API_URL + `api/v1/questions/?page=${page}&page_size=${page_size}`

        if (search) {
            url += `&search=${search}`
        }

        if (selectedCategory) {
            url += `&category_id=${selectedCategory}`
        }

        if (selectedType) {
            url += `&type_id=${selectedType}`
        }

        UIStore.update((s) => {
            s.loading = true
        })
        console.log(currentPage + 1, currentPage, rowsPerPage)
        fetch(url, {
            headers: {
                "Authorization": 'Token ' + localStorage.token
            },
            method: "get",
        }).then((res) => {
            UIStore.update((s) => {
                s.loading = false
            })
            if (res.status !== 200) {

            } else {
                res.json().then((data) => {
                    handleApiJson(data)
                    setTypesList(data['data'])
                    setTotalPages(data['pagination']['pages_count'])
                    setCurrentPage(data['pagination']['current_page'])
                })
            }
        })
    }

    if (redirect) {
        return <Redirect to={redirect}/>
    }

    return isAdmin ? <div>
        {typesList && <Container style={{maxWidth: 'none'}} className={classes.paper} component="div" maxWidth="xs">
            <CssBaseline/>
            <Grid container spacing={3}>
                {typesList.map(x => <Grid key={x.slug} item md={6} xs={12} lg={4}><QuestionCard key={x.slug}
                                                                                                history={props.history}
                                                                                                data={x}
                                                                                                is_type={props.is_type}/></Grid>)}
            </Grid>

            {totalPages > 1 &&
            <Pagination className={classes.pagination} count={totalPages} page={currentPage} color="secondary"
                        onChange={handleChangePage}/>}
        </Container>}

        <br/><br/><br/><br/><br/><br/><br/>
        <Fab
            variant="extended"
            size="large"
            color="primary"
            aria-label="add"
            onClick={handleClickOpen}
            className={classes.fab}
        >
            <FilterList className={classes.extendedIcon}/>
            Filter
        </Fab>
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Filters</DialogTitle>
            <DialogContent>
                <TextField
                    margin="dense"
                    label="Search"
                    type="text"
                    fullWidth
                    value={search}
                    onChange={e => {
                        setSearch(e.target.value)
                    }}
                />
                <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="age-native-simple">Type</InputLabel>
                    <Select
                        native={isMobile}
                        value={selectedType}
                        onChange={e => {
                            setSelectedType(e.target.value)
                        }}>
                        {isMobile ? <option value={0}>All</option> : <MenuItem value={0}>All</MenuItem>}
                        {isMobile ? types.map(t => <option value={t.id}>{t.title}</option>) :
                            types.map(t => <MenuItem value={t.id}>{t.title}</MenuItem>)
                        }

                    </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="age-native-simple">Category</InputLabel>
                    <Select
                        native={isMobile}
                        value={selectedCategory}
                        onChange={e => {
                            setSelectedCategory(e.target.value)
                        }}>
                        {isMobile ? <option value={0}>All</option> : <MenuItem value={0}>All</MenuItem>}
                        {isMobile ? categories.map(t => <option value={t.id}>{t.title}</option>) :
                            categories.map(t => <MenuItem value={t.id}>{t.title}</MenuItem>)
                        }
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleCloseApply} color="primary">
                    Apply
                </Button>
                <Button onClick={handleCloseReset} color="primary">
                    Reset
                </Button>
            </DialogActions>
        </Dialog>

    </div> : <div></div>
}