import React, {useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import {authStore, globalSnackError, UIStore} from "./stores";
import history from "history/browser";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import {convertIsoToReadable, handleApiJson, prettifyCamelCase} from "./utils";
import Avatar from "@material-ui/core/Avatar";
import Fab from "@material-ui/core/Fab";
import {CheckCircle, Edit, Error, Save} from "@material-ui/icons";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns'
import TextField from "@material-ui/core/TextField";
import S from "./Translations";
import {FormControl, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Chip from "@material-ui/core/Chip";
import {isMobile} from "react-device-detect";
import Paper from "@material-ui/core/Paper";


const UserProfileStyles = makeStyles((theme) => ({
    table: {
        minWidth: isMobile ? "100%" : "400px",
        maxWidth: isMobile ? "none" : "500px"
    },
    tableRoot: {
        minWidth: isMobile ? "100%" : "400px",
        maxWidth: isMobile ? "none" : "500px",
        marginTop: 20
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        maxWidth: "none",
        position: "relative",
        paddingTop: "60px",
        justifyContent: "center",
        alignItems: "center"
    },
    formControl: {
        minWidth: 120,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        backgroundColor: theme.palette.secondary.main,
        top: 10,
        left: "0px",
        right: "0px",
        width: "100px",
        height: "100px",
        margin: "auto",
        zIndex: 100,
        position: "absolute",
        fontSize: "50px",
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    fab: {
        bottom: "45px", right: "40px", position: "fixed"
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    sectionTitle: {
        fontSize: 16,
        marginTop: 30
    },
    pos: {
        marginBottom: 12,
    },
    id: {
        position: "absolute", top: 10, left: 15
    },
    chip: {
        position: "absolute", top: 10, right: 15
    },
    root: {
        position: 'relative',
        paddingTop: 50,
        minWidth: isMobile ? "100%" : "400px",
        maxWidth: isMobile ? "none" : "500px"
    },
}));

export function UserProfile(props) {
    const classes = UserProfileStyles()

    const [data, setData] = React.useState(null);
    const [expiration, setExpiration] = React.useState(null);

    const [editMode, setEditMode] = React.useState(false)

    useEffect(() => {
        fetchData()

    }, [props.user_id])


    function fetchData() {
        if (!props.user_id) {
            return
        }
        UIStore.update((s) => {
            s.loading = true
        })
        fetch(process.env.REACT_APP_API_URL + `api/v1/users/${props.user_id}/`,
            {
                headers: {
                    "Authorization": 'Token ' + localStorage.token
                },
                method: "get"
            }).then((res) => {
            UIStore.update(s => {
                s.loading = false
            })
            if (res.status === 200) {
                res.json().then(data => {
                    setData(data['data'])
                    UIStore.update(s => {
                        s.pageTitle = `@${data['data']['username']}`
                    })
                    setExpiration(data['data']['expiration'] ? new Date(data['data']['expiration']) : null)
                })
            } else {
                globalSnackError.update(s => {
                    s.display = true;
                    s.text = "Failed fetching this user.";
                })
                history.back()
            }
        })
    }

    const isAdmin = authStore.useState(s => s.is_admin);
    const formRef = React.useRef();

    function changeItem() {
        UIStore.update((s) => {
            s.loading = true
        })
        fetch(process.env.REACT_APP_API_URL + `api/v1/users/${props.user_id}/`, {
            headers: {
                "Authorization": 'Token ' + localStorage.token,
            },
            method: "put",
            body: new FormData(formRef.current)
        }).then((res) => {
            UIStore.update(s => {
                s.loading = false
            })
            if (res.status === 200) {
                setData(data['data'])
                res.json().then((data) => {
                    handleApiJson(data)
                    setData(data['data'])
                    setExpiration(data['data']['expiration'] ? new Date(data['data']['expiration']) : null)
                })
            } else {

            }
        })
    }

    const types = {
        "login": "Logged In",
        "start_test_final": "Started a Final Test",
        "start_test_training": "Started a Training Test",
    }

    return <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {data && <Container className={classes.paper} component="div" maxWidth="xs">
            <CssBaseline/>
            <Avatar className={classes.avatar}>{data.username[0].toUpperCase()}</Avatar>
            <Card className={classes.root} variant={editMode ? "elevation" : "outlined"}>
                <CardContent style={{minHeight: 150}}>
                    {editMode ? <form autocomplete="off" ref={formRef} onSubmit={changeItem}>
                        <FormControl margin={'normal'} className={classes.formControl}>
                            <TextField defaultValue={data['username']} fullWidth required
                                       label={S.username} name="username" margin="normal"
                                       variant="outlined"/>
                            <TextField defaultValue={data['first_name']} fullWidth required
                                       label={S.firstName} name="first_name" margin="normal"
                                       variant="outlined"/>
                            <TextField defaultValue={data['last_name']} fullWidth required
                                       label={S.lastName} name="last_name" margin="normal"
                                       variant="outlined"/>
                            <TextField defaultValue={data['email']} fullWidth required
                                       label={S.emailAddress} name="email" margin="normal"
                                       variant="outlined"/>
                            <input type="hidden" name='expiration'
                                   value={expiration && expiration.toISOString()}/>
                            {isAdmin && <DateTimePicker
                                variant="inline"
                                ampm={true}
                                label="Expiration"
                                value={expiration}
                                inputVariant="outlined"
                                onChange={setExpiration}
                                onError={console.log}
                                disablePast
                                disabled={props.user_id === 'self'}
                                margin="normal"
                                format="yyyy/MM/dd HH:mm"
                                style={{width: "100%"}}

                            />}
                            {isAdmin && <FormControlLabel
                                control={<Switch disabled={props.user_id === 'self'}
                                                 defaultChecked={data['is_active']} value="true"
                                                 name="is_active"/>}
                                label="Active"
                            />}
                        </FormControl>

                    </form> : <div>
                        <Chip
                            icon={data.is_active ? <CheckCircle/> : <Error/>}
                            label={data.is_active ? 'Active' : 'Inactive'}
                            className={classes.chip}
                            color={data.is_active ? "primary" : "inherit"}
                        />
                        <Typography className={classes.id} color="textSecondary">
                            #{data.id}
                        </Typography>
                        <Typography className={classes.title} color="body1" gutterBottom>
                            {data.username}
                        </Typography>
                        <Typography className={classes.pos} color="bold">
                            {data.first_name} {data.last_name}
                        </Typography>
                        <Typography className={classes.pos} color="textSecondary">
                            {data.email}
                        </Typography>

                        <Typography variant="body2" component="p">
                            <b>Joined On:</b> {convertIsoToReadable(data.date_joined)}
                        </Typography>
                        {data.expiration && <Typography variant="body2" component="p">
                            <br/>
                            <b>Expires:</b> {convertIsoToReadable(data.expiration)}
                        </Typography>}
                    </div>
                    }
                </CardContent>
                <CardActions>
                    {editMode ? <Button onClick={() => {
                        changeItem()
                        setEditMode(!editMode)
                    }} size="small">Save</Button> : <Button onClick={() => {
                        setEditMode(!editMode)
                    }} size="small">Edit</Button>}
                </CardActions>
            </Card>
            <Typography style={{textTransform: "capitalize"}} component="h1" variant="h6"
                        color="inherit" noWrap className={classes.sectionTitle}>
                Test Statistics
            </Typography>
            {data && data.test_stats && <TableContainer className={classes.tableRoot} component={Paper}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableBody>
                        {Object.entries(data.test_stats).map(([key, value]) => {
                            return <TableRow key={key}>
                                <TableCell component="th" scope="row">{prettifyCamelCase(key)}</TableCell>
                                <TableCell align="right">{value}</TableCell>
                            </TableRow>
                        })}
                    </TableBody>
                </Table>

            </TableContainer>}

            <Typography style={{textTransform: "capitalize"}} component="h1" variant="h6"
                        color="inherit" noWrap className={classes.sectionTitle}>
                Recent Actions
            </Typography>

            {data && data.logs && <TableContainer className={classes.tableRoot} component={Paper}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Action Type</TableCell>
                            <TableCell align="right">Time</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.logs.map((row) => (
                            <TableRow key={row.created_at}>
                                <TableCell component="th" scope="row">{types[row.type]}</TableCell>
                                <TableCell align="right">{convertIsoToReadable(row.created_at)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>}
            <br/><br/><br/><br/><br/><br/><br/>
            {editMode ? <Fab
                variant="extended"
                size="large"
                color="primary"
                aria-label="add"
                onClick={() => {
                    changeItem()
                    setEditMode(!editMode)
                }}
                className={classes.fab}>
                <Save className={classes.extendedIcon}/>
                Save
            </Fab> : <Fab
                variant="extended"
                size="large"
                color="secondary"
                aria-label="add"
                onClick={() => {
                    setEditMode(!editMode)
                }}
                className={classes.fab}>
                <Edit className={classes.extendedIcon}/>
                Edit
            </Fab>}
        </Container>}
    </MuiPickersUtilsProvider>
}