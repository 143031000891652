import './App.css';


import React, {useEffect} from 'react';
import clsx from 'clsx';
import {makeStyles, ThemeProvider} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import {ListItem, ListItemIcon, Snackbar} from "@material-ui/core";
import ListItemText from '@material-ui/core/ListItemText';
import S from './Translations'
import {authStore, globalSnackError, globalSnackSuccess, UIStore} from "./stores";
import Alert from "./alert";
import {fetchSelf} from "./utils";
import {Link, Route, Switch, withRouter} from "react-router-dom";
import {
    Assignment,
    CancelOutlined,
    Category,
    Create,
    ExitToApp,
    ExpandLess,
    ExpandMore,
    Face,
    FilterList,
    Help,
    Home,
    LocalShipping,
    LockOpen,
    People,
    PersonAdd,
    Refresh,
    Settings,
} from "@material-ui/icons";
import theme from "./theme";
import {Helmet} from "react-helmet";
import {ForgotPassword, LoginPage, Register, ResetPassword} from "./auth";
import Hidden from "@material-ui/core/Hidden";
import Collapse from "@material-ui/core/Collapse";
import {TypesAdmin} from "./Types";
import {NewQuestion} from "./NewQuestion";
import {deviceType, isMobile} from "react-device-detect";
import {QuestionsAdmin} from "./Questions";
import EditQuestion from "./EditQuestion";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {UsersList} from "./UsersList";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import {UserProfile} from "./UserProfile";
import {mainActionButtonPress} from "./subjects";
import {Tests} from "./Tests";
import {AnswerTest} from "./AnswerTest";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import HomePage from "./Home";
import NewEasyTest from "./NewEasyTest";

const drawerWidth = 240;

const useStyles = makeStyles((t) => ({
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.default,
        boxShadow: 'none',
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        paddingLeft: !["tablet", "browser"].includes(deviceType) ? 0 : 240,
        width: "100%"
    },
    '@global': {
        '*::-webkit-scrollbar': {
            width: '0.8em'
        },
        '*::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '*::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            outline: '1px solid slategrey'
        }
    }
}));

function Dashboard(props) {
    const classes = useStyles();
    const [open] = React.useState(false);
    const title = UIStore.useState(s => s.pageTitle)
    const token = authStore.useState(s => s.token)
    const showError = globalSnackError.useState(s => s.display);
    const showSuccessful = globalSnackSuccess.useState(s => s.display);
    const errorText = globalSnackError.useState(s => s.text);
    const successfulText = globalSnackSuccess.useState(s => s.text);
    const isAdmin = authStore.useState(s => s.is_admin);
    let menuItems = [<Link style={{textDecoration: 'none', color: theme.palette.text.primary}} to="/">
        <ListItem button>
            <ListItemIcon>
                <Home/>
            </ListItemIcon>
            <ListItemText primary={S.home}/>
        </ListItem>
    </Link>,]

    useEffect(() => {
        fetchSelf()
    }, []);

    useEffect(() => {
        let unlisten = props.history.listen(({action, location}) => {
            setMobileOpen(false)
        });
        return () => {
            unlisten()
        }
    },);


    let noTokenMenu = [<Link style={{textDecoration: 'none', color: theme.palette.text.primary}} to="/register">
        <ListItem button>
            <ListItemIcon>
                <PersonAdd/>
            </ListItemIcon>
            <ListItemText primary={S.registerTitle}/>
        </ListItem>
    </Link>,
        <Link style={{textDecoration: 'none', color: theme.palette.text.primary}} to="/login">
            <ListItem button>
                <ListItemIcon>
                    <LockOpen/>
                </ListItemIcon>
                <ListItemText primary={S.signIn}/>
            </ListItem>
        </Link>
    ]

    let tokenMenu = [

    ]
    if (token) {
        menuItems = menuItems.concat(tokenMenu)
    } else {
        menuItems = menuItems.concat(noTokenMenu)
    }

    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };


    const mainListItems = (
        <div>
            {menuItems.map(t => t)}
        </div>
    );

    const [adminOpen, setAdminOpen] = React.useState(false);
    const [showLogout, setShowLogout] = React.useState(false);

    const [editQuestionSlug, setEditQuestionSlug] = React.useState(null);

    const handleAdminClick = () => {
        setAdminOpen(!adminOpen);
    };

    const loading = UIStore.useState(s => s.loading)
    const mainActionButton = UIStore.useState(s => s.mainActionButton)
    const drawer = (<div>
            <Divider/>
            <List>{mainListItems}</List>
            <Divider/>
            <List>

                {localStorage.token && <Link style={{textDecoration: 'none', color: theme.palette.text.primary}}
                                             to='/profile'><ListItem button>
                    <ListItemIcon>
                        <Face/>
                    </ListItemIcon>
                    <ListItemText primary={S.profile}/>
                </ListItem></Link>}
                {localStorage.token && <Link style={{textDecoration: 'none', color: theme.palette.text.primary}}
                                             to='/tests'><ListItem button>
                    <ListItemIcon>
                        <Assignment/>
                    </ListItemIcon>
                    <ListItemText primary={S.tests}/>
                </ListItem></Link>}
                {localStorage.token && <Link style={{textDecoration: 'none', color: theme.palette.text.primary}}
                                             to='/new_test'><ListItem button>
                    <ListItemIcon>
                        <Icon>add_box</Icon>
                    </ListItemIcon>
                    <ListItemText primary={S.newTest}/>
                </ListItem></Link>}
                {localStorage.token && <ListItem button onClick={() => {
                    setShowLogout(true)
                    setMobileOpen(false)
                }}>
                    <ListItemIcon>
                        <ExitToApp/>
                    </ListItemIcon>
                    <ListItemText primary={S.logout}/>
                </ListItem>}
                {isAdmin && <div><ListItem button onClick={handleAdminClick}>
                    <ListItemIcon>
                        <Settings/>
                    </ListItemIcon>
                    <ListItemText primary={S.admin}/>
                    {adminOpen ? <ExpandLess/> : <ExpandMore/>}
                </ListItem>
                    <Collapse in={adminOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <Link style={{textDecoration: 'none', color: theme.palette.text.primary}}
                                  to='/types'><ListItem button>
                                <ListItemIcon>
                                    <LocalShipping/>
                                </ListItemIcon>
                                <ListItemText primary={S.types}/>
                            </ListItem></Link>
                            <Link style={{textDecoration: 'none', color: theme.palette.text.primary}}
                                  to='/categories'><ListItem button>
                                <ListItemIcon>
                                    <Category/>
                                </ListItemIcon>
                                <ListItemText primary={S.categories}/>
                            </ListItem></Link>
                            <Link style={{textDecoration: 'none', color: theme.palette.text.primary}}
                                  to='/questions'><ListItem button>
                                <ListItemIcon>
                                    <Help/>
                                </ListItemIcon>
                                <ListItemText primary="Questions"/>
                            </ListItem></Link>
                            <Link style={{textDecoration: 'none', color: theme.palette.text.primary}}
                                  to='/new_question'><ListItem button>
                                <ListItemIcon>
                                    <Create/>
                                </ListItemIcon>
                                <ListItemText primary="New Question"/>
                            </ListItem></Link>
                            <Link style={{textDecoration: 'none', color: theme.palette.text.primary}}
                                  to='/users'><ListItem button>
                                <ListItemIcon>
                                    <People/>
                                </ListItemIcon>
                                <ListItemText primary="Users"/>
                            </ListItem></Link>
                        </List>
                    </Collapse></div>}
            </List></div>
    )
    const container = window !== undefined ? () => window.document.body : undefined;
    return (
        <div className={classes.root}>
            <ThemeProvider theme={theme}>
                    <Helmet>
                        <title>{title}</title>
                    </Helmet>
                    <CssBaseline/>
                    <AppBar position="fixed" className={clsx(classes.appBar, open && classes.appBarShift)}>
                        <Toolbar className={classes.toolbar}>
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerToggle}
                                className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                            >
                                <MenuIcon/>
                            </IconButton>
                            <Typography style={{
                                fontFamily:"Open Sans Condensed",
                                textTransform: "capitalize"}}
                                        component="h1"
                                        variant="h5"
                                        noWrap
                                        className={classes.title}>
                                {title}
                            </Typography>

                            {mainActionButton === 'test_new' &&
                            <Button
                                variant="contained"
                                color="primary"
                                size="medium"
                                onClick={() => {
                                    props.history.push('/new_test')
                                }}
                                startIcon={<Icon>add</Icon>}
                            >New Test</Button>
                            }
                            {mainActionButton === 'users_refresh' &&
                            <IconButton onClick={() => {
                                mainActionButtonPress.next('users_refresh')
                            }} color="inherit">
                                <Refresh/>
                            </IconButton>
                            }
                            {mainActionButton === 'quit_test' &&
                            <IconButton onClick={() => {
                                mainActionButtonPress.next('quit_test')
                            }} color="inherit">
                                <CancelOutlined/>
                            </IconButton>
                            }
                            {mainActionButton === 'questions_filter' &&
                            <IconButton onClick={() => {
                                mainActionButtonPress.next('questions_filter')
                            }} color="inherit">
                                <FilterList/>
                            </IconButton>}
                        </Toolbar>
                    </AppBar>

                {isMobile && <Hidden smUp implementation="css">
                    <SwipeableDrawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        onOpen={handleDrawerToggle}
                    >
                        {drawer}
                    </SwipeableDrawer>
                </Hidden>}
                {["tablet", "browser"].includes(deviceType) && <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>}

                <main className={classes.content}>
                    <Route path="/questions/:slug/edit" render={(routeProps) => {
                        UIStore.update(s => {
                            s.openGlobalModal = 'edit_question'
                            s.editQuestionReadOnly = false
                            s.editQuestionSlug = routeProps.match.params.slug
                        })
                    }}/>
                    <Route path="/questions/:slug/view" render={(routeProps) => {
                        UIStore.update(s => {
                            s.openGlobalModal = 'edit_question'
                            s.editQuestionReadOnly = true
                            s.editQuestionSlug = routeProps.match.params.slug
                        })
                    }}/>
                    <Switch>
                        <Route render={() => <Register/>} path="/register"/>
                        <Route render={() => <ForgotPassword/>} path="/forgot-password"/>
                        <Route render={() => <LoginPage/>} path="/login"/>
                        <Route render={(routeProps) => <AnswerTest history={routeProps.history}
                                                                   test_id={routeProps.match.params.id}/>}
                               path="/tests/:id/answer"/>
                        <Route render={() => <UserProfile user_id={"self"}/>} path="/profile"/>
                        <Route render={() => <Tests user_id={"self"}/>} path="/tests"/>
                        <Route render={(routeProps) => <UserProfile user_id={routeProps.match.params.id}/>}
                               path="/users/:id/edit"/>
                        <Route render={(routeProps) => <ResetPassword code={routeProps.match.params.code} user_id={routeProps.match.params.id}/>}
                               path="/password/recovery/:code/:id"/>

                        <Route render={() => <TypesAdmin is_type={true}/>} path="/types"/>
                        <Route render={(routeProps) => <UsersList history={routeProps.history}/>} path="/users"/>
                        <Route render={() => <TypesAdmin is_type={false}/>} path="/categories"/>
                        <Route
                            render={(routeProps) => <NewQuestion history={routeProps.history} edit_mode={false}/>}
                            path="/new_question"/>
                        <Route render={(routeProps) => <QuestionsAdmin history={routeProps.history}/>}
                               path="/questions"/>
                        <Route render={(routeProps) => <NewEasyTest history={routeProps.history}/>}
                               path="/new_test"/>
                        <Route render={(routeProps) => <HomePage history={routeProps.history}/>}
                               path="/"/>
                        <Route render={() => <div>Not Found</div>}/>
                    </Switch>

                        <Snackbar open={showError} autoHideDuration={6000} onClose={() => {
                            globalSnackError.update(s => {
                                s.display = false;
                                s.text = '';
                            })
                        }}>
                            <Alert severity="error">
                                {errorText}
                            </Alert>
                        </Snackbar>

                        <Snackbar open={showSuccessful} autoHideDuration={6000} onClose={() => {
                            globalSnackSuccess.update(s => {
                                s.display = false;
                                s.text = '';
                            })
                        }}>
                            <Alert severity="success">
                                {successfulText}
                            </Alert>
                        </Snackbar>
                    <Backdrop style={{zIndex: 1000000}} open={loading}>
                        <CircularProgress color="inherit"/>
                    </Backdrop>
                    <EditQuestion question_slug={editQuestionSlug}/>
                    <Dialog
                        open={showLogout}
                        onClose={() => {
                            setShowLogout(false)
                        }}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                        <DialogTitle>{`Are you sure ?`}</DialogTitle>
                        <DialogActions>
                            <Button onClick={() => {
                                setShowLogout(false)
                            }} autoFocus color="primary">
                                No
                            </Button>
                            <Button onClick={() => {
                                delete localStorage.token
                                authStore.update(s => {
                                    s.token = null
                                    s.is_admin = false
                                })
                                setShowLogout(false)
                                props.history.push('/login')
                            }} color="secondary">
                                Yes
                            </Button>
                        </DialogActions>
                    </Dialog>
                </main>
            </ThemeProvider>
        </div>
    );
}

const DashboardWithRouter = withRouter(Dashboard);

function App() {
    return (
        <div className="App">
            <DashboardWithRouter/>
        </div>
    );
}


export default App;